import {createContext, ReactNode, useContext} from 'react';
import {
  FormProvider as RHFormProvider,
  useFormContext as useRHFormContext,
  UseFormReturn,
} from 'react-hook-form';

export const useFormContext = () => {
  const form = useRHFormContext();
  return form;
};

type Form = {
  ref: React.RefObject<HTMLFormElement>;
  context: UseFormReturn<any, any, any>;
  submit: () => void;
};

const Form = createContext<Form | null>(null);

export const useForm = () => {
  const context = useContext(Form);
  if(!context) {
    throw new Error('Form context not provided');
  }
  return context;
};

export const FormProvider = ({
  formRef,
  context,
  children,
}: {
  formRef: Form['ref'];
  context: Form['context'];
  children: ReactNode;
}) => {
  const submit = () => {
    formRef.current?.dispatchEvent(
      new Event('submit', {cancelable: true, bubbles: true})
    );
  };
  return (
    <Form.Provider value={{ref: formRef, submit, context}}>
      <RHFormProvider {...context}>{children}</RHFormProvider>
    </Form.Provider>
  );
};
