import {updateNotificationSettings} from 'payble-api-client/consumer';
import {NotificationSettingGroupConfig} from 'payble-shared';
import {Form} from 'payble-ui/src/components/Form';
import {z} from 'zod';

type NotificationTypeBool = {
  sms?: boolean;
  email?: boolean;
};

const notificationTypes: {value: keyof NotificationTypeBool; label: string}[] =
  [
    {value: 'sms', label: 'SMS'},
    {value: 'email', label: 'Email'},
  ];

const schema = updateNotificationSettings['requestSchema'];
type FormData = z.infer<typeof schema>;

export const NotificationSettingTable = ({
  defaultValues,
  onSave,
  disabled,
  disabledType,
}: {
  defaultValues?: FormData;
  disabled?: boolean;
  disabledType?: NotificationTypeBool;
  onSave: (data: FormData) => Promise<void>;
}) => {
  const readyTypes = notificationTypes.filter(({value}) =>
    ['sms', 'email'].includes(value)
  );

  return (
    <Form
      mode="onChange"
      schema={updateNotificationSettings['requestSchema']}
      onSubmit={onSave}
      defaultValues={defaultValues}
      disabled={disabled}
    >
      <table className="w-full table-auto">
        <thead className="border-b">
          <tr className="text-gray-500 bg-gray-100 border-b">
            {[{label: ''}].concat(readyTypes).map(type => (
              <th
                key={type.label}
                className="h-12 px-4 py-2 font-medium text-center align-middle text-muted-foreground"
              >
                {type.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(NotificationSettingGroupConfig)
            .filter(([, notification]) => notification.editable)
            .map(([name, notification]) => (
              <tr key={name} className="text-gray-500 border-b">
                <td className="p-4 px-4 py-2 text-left align-middle">
                  {notification.label}
                  <p className="text-sm text-gray-400">
                    {notification.description}
                  </p>
                </td>
                {readyTypes.map(type => (
                  <td
                    key={`${name}.${type}`}
                    className="p-4 px-4 py-2 text-center align-middle"
                  >
                    <Form.Switch
                      name={`${name}.${type.value}`}
                      disabled={disabledType?.[type.value] ?? false}
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      <Form.SubmitButton className="mb-4">Save</Form.SubmitButton>
    </Form>
  );
};
