import {TermDefinitionSchema} from 'payble-shared/src/app-config/ConsumerAppConfig';
import {z} from 'zod';
import {zAbsoluteDate, zNumberString} from './generic';
import {CheckoutPreviewSchema, ConsumerPaymentMethodSummary} from './payment';

const StandardFrequencies = z.enum(['weekly', 'fortnightly', 'monthly']);
const Amount = zNumberString.pipe(z.number().int().positive());
export const CheckoutRequestSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('pay'),
    amount: zNumberString.pipe(z.number().int().positive()),
    date: zAbsoluteDate.optional(),
  }),
  z.object({
    type: z.literal('pay-every-x'),
    frequency: StandardFrequencies,
    startAt: zAbsoluteDate,
  }),
  z.object({
    type: z.literal('pay-x-every-z'),
    frequency: StandardFrequencies,
    startAt: zAbsoluteDate,
    amount: Amount,
  }),
  z.object({
    type: z.literal('balanced-pay-every-x'),
    frequency: StandardFrequencies,
    startAt: zAbsoluteDate,
  }),
  z.object({
    type: z.literal('pay-x-on-y'),
    frequency: z.enum(['automatic']),
  }),
  z.object({
    type: z.literal('smooth-pay'),
    frequency: StandardFrequencies,
    startAt: zAbsoluteDate,
    offPeriodAmount: z.number().int().nonnegative(),
  }),
  z.object({
    type: z.literal('catch-up'),
    frequency: StandardFrequencies,
    startAt: zAbsoluteDate,
    targetDate: zAbsoluteDate,
  }),
]);
export const StartCheckoutRequestSchema = z.object({
  billerId: z.string().uuid(),
  account: z.object({
    externalId: z.string(),
    type: z.string(),
    verificatioCode: z.string().optional(),
  }),
  checkout: CheckoutRequestSchema,
});
const CheckoutStepSchema = z.discriminatedUnion('step', [
  /**
   * When terms and conditions are displayed on a single page for the user to
   * accept.
   */
  z.object({step: z.literal('terms'), terms: TermDefinitionSchema}),
  z.object({step: z.literal('select_payment_method')}),
  z.object({step: z.literal('create_payment_method')}),
  /**
   * When there is a payment schedule to be displayed to the user
   */
  z.object({step: z.literal('preview')}),
  /**
   * Generic "Confirm this" step
   */
  z.object({step: z.literal('confirm')}),
]);

export type CheckoutStep = z.output<typeof CheckoutStepSchema>;
export type CheckoutStepByType<T extends CheckoutStep['step']> = Extract<
  CheckoutStep,
  {step: T}
>;

export const ReceiptLayoutItemSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('category_header'),
    content: z.string(),
  }),
  z.object({
    type: z.literal('dictionary_card'),
    items: z.array(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    ),
  }),
]);

export const ReceiptLayoutSchema = z.object({
  type: z.enum(['payment', 'plan']),
  createdId: z.string().uuid(),
  items: z.array(ReceiptLayoutItemSchema),
});

export const ConsumerCheckoutSchema = z.object({
  checkoutId: z.string().uuid(),
  paymentMethod: ConsumerPaymentMethodSummary.optional(),
  steps: z.array(CheckoutStepSchema),
  preview: CheckoutPreviewSchema,
  receipt: ReceiptLayoutSchema.optional(),
});
