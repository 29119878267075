import {errs} from 'payble-shared';
import {z} from 'zod';

const STORAGE_KEY = 'payble-checkout';

const storageSchema = z.record(
  z.string(),
  z.object({
    ct: z.string(),
    sourcePath: z.string().optional(),
  })
);

/**
 * We store the checkout token (ct) in local storage so that we can store a bit
 * of persisted state and keep the token out of the query parameters.
 */
export const CheckoutTokens = {
  read(checkoutId: string): z.output<typeof storageSchema>[string] {
    const raw = localStorage.getItem(STORAGE_KEY);
    if (!raw) {
      throw errs.ClientError.create('Checkout not found', {
        checkoutId,
      });
    }

    try {
      const parsed = storageSchema.parse(JSON.parse(raw));
      const found = parsed[checkoutId];
      if (found) {
        return found;
      }
    } catch (e) {
      throw errs.ClientError.wrap(e, 'Invalid checkout', {
        checkoutId,
      });
    }
    throw errs.ClientError.create('Invalid checkout', {
      checkoutId,
    });
  },

  write(checkoutId: string, data: z.input<typeof storageSchema>[string]) {
    let writable: z.input<typeof storageSchema> = {[checkoutId]: data};

    try {
      const existing = localStorage.getItem(STORAGE_KEY);
      if (existing) {
        const parsed = storageSchema.parse(JSON.parse(existing));
        writable = {...parsed, ...writable};
      }
    } catch {
      /* empty */
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(writable));
  },
};
