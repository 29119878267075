import {FC, useEffect, useMemo, useRef, useState} from 'react';
import React, {ReactNode} from 'react';
import {Input} from './Input';
import {TermDefinition} from 'payble-shared/src/app-config/ConsumerAppConfig';
import {Dialog, Transition} from '@headlessui/react';
import DOMPurify from 'dompurify';
import snarkdown from 'snarkdown';

function markDownToHtml(markdown: string, allowedTags: string[]): string {
  // hook to make all links to open a new window
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
  });
  return DOMPurify.sanitize(snarkdown(markdown), {
    ALLOWED_TAGS: allowedTags,
  });
}

const TermsModal: React.FC<{
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  terms: string[];
}> = ({isOpen, setOpen, terms}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-8">
              <div className="mt-3 sm:mt-5">
                <ul className="list-disc mt-2">
                  {terms.map((term, index) => (
                    <li
                      key={index}
                      className="text-sm text-gray-500 mt-1"
                      dangerouslySetInnerHTML={{
                        __html: markDownToHtml(term, ['a', 'u']),
                      }}
                    ></li>
                  ))}
                </ul>
              </div>
              <div className="mt-5 sm:mt-6 inline-flex justify-center align-center w-full">
                <button
                  type="button"
                  className="inline-flex justify-center align-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={() => setOpen(false)}
                >
                  OK
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export interface InputTermsProps {
  value: boolean;
  onChange: (value: boolean) => void;
  terms: TermDefinition[];
  disabled?: boolean;
}

const StyledCheckbox: React.FC<{
  children: ReactNode;
  name: string;
  id: string;
  onClick: () => void;
  checked: boolean;
  disabled: boolean;
}> = ({children, name, id, onClick, checked, disabled}) => {
  return (
    <div className="w-full flex flex-col mb-2">
      <div className="flex items-center space-between w-full">
        <div className="text-xs text-gray-500 mr-2 flex-grow">{children}</div>
        <div>
          <Input
            type="checkbox"
            name={name}
            checked={checked}
            onClick={onClick}
            id={id}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export const TermBody = ({term}: {term: TermDefinition}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <>
      {term.definition.map(def => {
        if (def.type === 'inline') {
          return (
            <React.Fragment key={def.itemId}>
              {def.content}&nbsp;
            </React.Fragment>
          );
        }

        if (def.type === 'link') {
          return (
            <a
              key={def.itemId}
              href={def.url}
              className="text-blue-600 underline"
              target="_blank"
            >
              {def.content}&nbsp;
            </a>
          );
        }

        if (def.type === 'modal') {
          return (
            <React.Fragment key={def.itemId}>
              <a
                className="text-blue-600 underline"
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setModalOpen(true);
                }}
              >
                {def.contentTrigger}&nbsp;
              </a>
              <TermsModal
                isOpen={modalOpen}
                setOpen={setModalOpen}
                terms={def.contentLines}
              />
            </React.Fragment>
          );
        }

        if (def.type === 'list') {
          return (
            <ul key={def.itemId}>
              {def.contentLines.map(item => (
                <li key={item}>{item}&nbsp;</li>
              ))}
            </ul>
          );
        }
      })}
    </>
  );
};

const InputTerms: FC<InputTermsProps> = ({
  value,
  onChange,
  terms,
  disabled,
}) => {
  const [checkedTerms, setCheckedTerms] = useState<Record<string, boolean>>({});

  const accepted = useMemo(
    () => terms.every(term => checkedTerms[term.termId]),
    [checkedTerms, terms]
  );

  useEffect(() => {
    if (value !== accepted) {
      onChange(accepted);
    }
  }, [value, onChange, accepted]);

  const onToggle = (name: string) => {
    setCheckedTerms({
      ...checkedTerms,
      [name]: !checkedTerms[name],
    });
  };

  const isChecked = (name: string) => {
    return checkedTerms[name] ?? false;
  };

  return (
    <div className="w-full col-span-6">
      {terms.map(term => (
        <StyledCheckbox
          key={term.termId}
          name={term.termId}
          checked={isChecked(term.termId)}
          onClick={() => onToggle(term.termId)}
          id={term.termId}
          disabled={disabled ?? false}
        >
          <TermBody term={term} />
        </StyledCheckbox>
      ))}
    </div>
  );
};

InputTerms.displayName = 'InputTerms';

export {InputTerms};
