import {DomainError} from './DomainError';

export class UnexpectedError extends DomainError {
  get className() {
    return 'UnexpectedError';
  }
  get code() {
    return 'FAILED';
  }
}
export class AuthenticationError extends DomainError {
  get className() {
    return 'AuthenticationError';
  }
  get httpCode() {
    return 401;
  }
  get code() {
    // This code is required for consumer-web to continue functioning.
    return 'UNAUTHENTICATED';
  }
}

export class PersistenceError extends DomainError {
  get className() {
    return 'PersistenceError';
  }
  get code() {
    return 'PERSISTENCE';
  }
}

export class AuthorizationError extends DomainError {
  get className() {
    return 'AuthorizationError';
  }
  get httpCode() {
    return 403;
  }
  get code() {
    return 'AUTHORIZATION';
  }
}

export class ClientError extends DomainError {
  get className() {
    return 'ClientError';
  }
  get httpCode() {
    return 400;
  }
  get code() {
    return 'CLIENT';
  }
}

export class ValidationError extends ClientError {
  get className() {
    return 'ValidationError';
  }
  get code() {
    return 'VALIDATION';
  }
}

export class NotFoundError extends DomainError {
  get className() {
    return 'NotFoundError';
  }
  get httpCode() {
    return 404;
  }
  get code() {
    return 'NOT_FOUND';
  }

  static of(modelName: string, context?: Record<string, unknown>) {
    return NotFoundError.create(`${modelName} not found`, context);
  }
}

export class VersionMismatchError extends DomainError {
  get className() {
    return 'VersionMismatchError';
  }
  get httpCode() {
    return 400;
  }
  get code() {
    return 'VERSION_MISMATCH';
  }
}

export class PaymentGatewayError extends DomainError {
  get className() {
    return 'PaymentGatewayError';
  }
  get httpCode() {
    return 400;
  }

  declare context: {
    response?: unknown;
  };

  get response(): unknown {
    return this.context.response ?? null;
  }

  get code() {
    return 'PAYMENT_GATEWAY';
  }
}

export class TimeoutError extends DomainError {
  get className() {
    return 'TimeoutError';
  }
  get httpCode() {
    return 500;
  }

  get code() {
    return 'TIMEOUT';
  }
}

export class AccountVerificationError extends DomainError {
  get className() {
    return 'AccountVerificationError';
  }
  get httpCode() {
    return 400;
  }

  declare context: {
    type?: (typeof AccountVerificationError.types)[number];
  };
  static types = ['required', 'failed'] as const;

  get code() {
    return 'ACCOUNT_VERIFICATION';
  }

  dehydrateContext(): Record<string, unknown> {
    return {
      type: this.type,
    };
  }

  get type(): (typeof AccountVerificationError.types)[number] {
    const type = this.context.type;
    if (!type) {
      return 'failed';
    }
    return AccountVerificationError.types.includes(type) ? type : 'failed';
  }
}

export class ContactAlreadyExists extends DomainError {
  get className() {
    return 'ContactConflictError';
  }
  get httpCode() {
    return 409;
  }

  declare context: {
    type: (typeof ContactAlreadyExists.types)[number];
    contactId: string;
  };

  static types = ['conflictMobileAuth', 'conflictEmailAuth'] as const;

  get code() {
    return 'CONTACT_ALREADY_EXISTS';
  }

  dehydrateContext(): Record<string, unknown> {
    return {
      type: this.type,
      contactId: this.context.contactId,
    };
  }

  get type(): (typeof ContactAlreadyExists.types)[number] {
    return this.context.type;
  }
}
