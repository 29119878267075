import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';
import {AuthMethodSchema, AuthMethodTypeSchema} from '../schemas/auth';

export const loginStart = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'POST /auth/start',
  requestSchema: AuthMethodSchema,
  responseSchema: z.object({
    loginIdentity: z.string(),
    authMethod: AuthMethodTypeSchema,
  }),
});

export const loginComplete = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'POST /auth/complete',
  requestSchema: AuthMethodSchema.and(
    z.object({
      verificationCode: z.string().length(6).trim(),
    })
  ),
  responseSchema: z.object({
    id_token: z.string(),
  }),
});

export const logout = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /auth/logout',
  requestSchema: null,
  responseSchema: null,
});
