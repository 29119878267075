export const NotificationTypes = [
  {value: 'sms', label: 'SMS'},
  {value: 'email', label: 'Email'},
] as const;

export type NotificationTypeValue = (typeof NotificationTypes)[number]['value'];

export enum NotificationKey {
  reEngageContact1 = 're-engage-contact-1',
  reEngageContact2 = 're-engage-contact-2',
  paymentSucceeded = 'payment_succeeded',
  instalmentReminder = 'instalment_reminder',
  paymentReceipt = 'payment_receipt',
  directDebitConfirmation = 'direct_debit_confirmation_email',
}

export enum NotificationSettingGroupName {
  paymentSuccess = 'payment_success',
  paymentReminder = 'payment_reminder',
  paymentReceipt = 'payment_receipt',
  paymentConfirmation = 'payment_confirmation',
}

type Contact = {
  props: {
    authMethod: 'mobile' | 'email';
    email: string | null;
    mobile: string | null;
  };
};

export const getTypeDefaults = (contact: Contact) => {
  return {
    sms: contact.props.authMethod === 'mobile' && !!contact.props.mobile,
    email: contact.props.authMethod === 'email' && !!contact.props.email,
  };
};

export const NotificationSettingGroupConfig: Record<
  NotificationSettingGroupName,
  {
    name: NotificationSettingGroupName;
    label: string;
    description: string;
    editable?: boolean;
    settings?: NotificationKey[];
    defaults: (contact: Contact) => {
      [K in NotificationTypeValue]?: boolean;
    };
  }
> = {
  [NotificationSettingGroupName.paymentSuccess]: {
    name: NotificationSettingGroupName.paymentSuccess,
    label: 'Successful payments',
    description: 'Notifications about successful payments.',
    editable: true,
    settings: [NotificationKey.paymentSucceeded],
    defaults: getTypeDefaults,
  },
  [NotificationSettingGroupName.paymentReminder]: {
    name: NotificationSettingGroupName.paymentReminder,
    label: 'Upcoming payment reminders',
    editable: true,
    description: 'Notifications about upcoming payment reminders.',
    settings: [
      NotificationKey.instalmentReminder,
      NotificationKey.reEngageContact1,
      NotificationKey.reEngageContact2,
    ],
    defaults: getTypeDefaults,
  },
  [NotificationSettingGroupName.paymentReceipt]: {
    name: NotificationSettingGroupName.paymentReceipt,
    label: 'Payment receipts',
    editable: false,
    description: '',
    settings: [NotificationKey.paymentReceipt],
    defaults: () => ({sms: false, email: true}),
  },
  [NotificationSettingGroupName.paymentConfirmation]: {
    name: NotificationSettingGroupName.paymentConfirmation,
    label: 'Payment confirmation',
    editable: false,
    description: '',
    settings: [NotificationKey.directDebitConfirmation],
    defaults: () => ({
      sms: false,
      email: true,
    }),
  },
};
