import {z} from 'zod';
import {FormConfig} from '../../types';

export const cancelation_reasons = {
  title: 'Cancelation Reasons',
  configType: 'biller',

  validation: z.object({
    instalmentPlan: z
      .array(z.object({reason: z.string().trim()}))
      .refine(v => new Set(v.map(({reason}) => reason)).size === v.length, {
        message: 'Reason must be unique',
      }),
  }),

  form: [
    {
      label: 'Instalment Plan Cancelation Reasons',
      type: 'list',
      name: 'instalmentPlan',
      fields: [
        {
          label: 'Reason',
          name: 'reason',
          type: 'text',
        },
      ],
    },
  ],
} satisfies FormConfig;
