import {useDebouncedCallback} from 'use-debounce';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './Command';
import {CommandList} from 'cmdk';
import {useState} from 'react';
import {Check} from 'lucide-react';
import {cn} from '../lib/utils';

type Option = {
  value: string;
  label?: React.ReactNode;
};

export type AutocompleteProps = {
  options?: Option[];
  placeholder?: string;
  emptyMessage?: string;
  onSelect?: (value: string) => void;
  onSearchChange?: (value: string) => Promise<void> | void;
  disabled?: boolean;
};

export const Autocomplete = ({
  options,
  placeholder,
  emptyMessage,
  onSelect,
  disabled,
  onSearchChange,
}: AutocompleteProps) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>();
  const handleOnSearchChange = useDebouncedCallback(async value => {
    setLoading(true);
    try {
      await onSearchChange?.(value);
    } finally {
      setLoading(false);
    }
  }, 300);

  return (
    <Command shouldFilter={false}>
      <CommandInput
        disabled={disabled}
        placeholder={placeholder}
        onValueChange={handleOnSearchChange}
        isLoading={loading}
      />
      <CommandList>
        <CommandEmpty>{emptyMessage}</CommandEmpty>
        <CommandGroup>
          {options?.map(option => (
            <CommandItem
              key={option.value}
              value={option.value}
              onSelect={(...args) => {
                setValue(option.value);
                onSelect?.(...args);
              }}
            >
              {option.label}
              <Check
                className={cn(
                  'ml-auto',
                  option.value === value ? 'opacity-100' : 'opacity-0'
                )}
              />
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
