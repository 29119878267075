import {useSetupNavigate} from 'features/setup/hooks/useSetupNavigate';
import {BillerConfig, PaymentOption} from 'payble-shared';

type HandleOnNextSetupOptions = {
  mode: PaymentOption['value'];
  billerConfig: BillerConfig;
  navigate: ReturnType<typeof useSetupNavigate>;
};

/**
 * This function is an exact of what was used  before moving to setup flow.
 * This function should not live forever and should eventually be removed and replaced with calls to other setup flows
 * when other parts of the setup flow are implemented.
 */
export function handleOnNextSetupOptions({
  mode,
  billerConfig,
  navigate,
}: HandleOnNextSetupOptions) {
  switch (mode) {
    case 'PAY_AMOUNT':
      // user can select now or scheduled
      navigate('/biller/:slug/setup/one-off', {mode});
      break;
    case 'PAYMENT_MODE':
      // user cannot select now or scheduled, take them directly to the amount
      navigate('/biller/:slug/setup/one-off/amount', {mode});
      break;
    case 'PAY_AUTO':
      navigate('/biller/:slug/setup/auto', {mode});
      break;
    case 'PAY_X_EVERY_Z':
      navigate('/biller/:slug/setup/flexible', {mode});
      break;
    case 'PAY_EVERY_X':
      navigate(
        billerConfig.extendPlanTerms
          ? '/biller/:slug/setup/extended-flexible-plan'
          : '/biller/:slug/setup/flexible-plan',
        {mode}
      );

      break;
    case 'PAY_BALANCED':
      navigate('/biller/:slug/setup/balanced', {mode});
      break;
    case 'PAY_SMOOTH':
      navigate('/biller/:slug/setup/smooth', {mode});
      break;
    case 'PAY_CATCH_UP':
      navigate('/biller/:slug/setup/catch-up', {mode});
  }
}
