import {MaxContainer} from 'payble-ui';
import {Footer} from '../../features/navigation/Footer';
import {Navbar} from '../../features/navigation/Navbar';

export const BasePage: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div className="flex flex-col justify-between min-h-screen bg-gray-100">
      <Navbar />
      <div className="flex-1 h-full contents"> {children} </div>
      <Footer />
    </div>
  );
};

export const BaseSetupPage: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  return (
    <BasePage>
      <MaxContainer>{children}</MaxContainer>
    </BasePage>
  );
};
