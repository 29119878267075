import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router';

import {getBillerSlugFromUrl} from 'lib/url';

import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {navigate} from 'lib/navigation/routes';
import {HomeIcon} from '@heroicons/react/20/solid';
import {Debbie} from 'components/organisms/Debbie';
import {buttonClasses} from 'lib/styles';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {TryAgain} from 'components/molecules/TryAgain';
import {PaymentMethodMenu} from '../components/PaymentMethodMenu';
import {ChoosePaymentMethod} from 'components/organisms/ChoosePaymentMethod';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {useAPIMutation, useAPIQuery} from 'lib/api';
import {auth} from '../../../lib/authv2/auth';

export const ChangePaymentMethod: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const paymentMethods = auth.usePaymentMethods();
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const queryParams = new URLSearchParams(useLocation().search);
  const paymentMethodIdFromParams = queryParams.get('paymentMethodId');
  // Get current payment method so we can show it as selected
  if (billerSlug === '') return <>Cannot load without a biller slug</>;
  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;

  // When page first loads ensure we have the most up to date contact data
  useEffect(() => {
    document.title = 'Payble - Change Payment Method';
  }, []);

  const {
    data: instalmentPlan,
    error: instalmentPlanError,
    isLoading: instalmentPlanLoading,
  } = useAPIQuery('getInstalmentPlan', {
    data: instalmentPlanId ? {instalmentPlanId} : undefined,
  });

  const {
    mutate: changePaymentMethod,
    isPending: changePaymentMethodLoading,
    error: changePaymentMethodError,
  } = useAPIMutation('changeInstalmentPlanPaymentMethod', {
    query: {
      onSuccess: () => {
        navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
          slug: billerSlug,
          instalmentPlanId,
        });
      },
    },
  });

  const [paymentMethodId, setPaymentMethodId] = useState(
    instalmentPlan?.paymentMethodId
  );

  if (instalmentPlanLoading || changePaymentMethodLoading) return <Loading />;
  if (instalmentPlanError)
    return (
      <TryAgain
        errorMessage={instalmentPlanError.message}
        onClick={() => {
          navigate('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );

  const hasPaymentMethodChanged =
    instalmentPlan?.paymentMethodId !== paymentMethodId &&
    paymentMethodId !== undefined;

  const onChangePaymentMethod = async (
    __e: React.MouseEvent<HTMLButtonElement>
  ) => {
    await changePaymentMethod({
      instalmentPlanId,
      paymentMethodId: (paymentMethodIdFromParams || paymentMethodId)!,
    });
  };

  if (!instalmentPlan) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops! Something went wrong."
        debbieMessage="Sorry, there's no data found for the payment plan you're looking for. Please try again by clicking below."
        onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
      />
    );
  }

  const selectedPaymentMethodId =
    paymentMethodIdFromParams || instalmentPlan.paymentMethodId;

  return (
    <div className="py-6 lg:py-16">
      <Debbie
        title="Choose a new payment method"
        message="Select or add a new method of payment from the options below"
      />
      <div className="flex mt-5 mb-2">
        <div className="flex-1">
          <nav className="flex" aria-label="Breadcrumb">
            <ol
              role="list"
              className="flex px-6 space-x-4 bg-white rounded-md shadow"
            >
              <li className="flex">
                <div className="flex items-center">
                  <button
                    onClick={() =>
                      navigate('/biller/:slug', {slug: billerSlug})
                    }
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="flex-shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </button>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <button
                    onClick={() =>
                      navigate(
                        '/biller/:slug/instalment-plan/:instalmentPlanId',
                        {slug: billerSlug, instalmentPlanId}
                      )
                    }
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    Payment Plan
                  </button>
                </div>
              </li>
            </ol>
          </nav>
        </div>

        <div className="flex pl-4">
          <PaymentMethodMenu slug={billerSlug} />
        </div>
      </div>

      <div className="mt-5 overflow-hidden shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="flex flex-col">
            <ChoosePaymentMethod
              paymentMethods={paymentMethods}
              selectedPaymentMethodId={selectedPaymentMethodId}
              onChange={setPaymentMethodId}
            />
            <div className="my-3 font-base"></div>
            {changePaymentMethodError && (
              <div>
                <ErrorMessage message={changePaymentMethodError.message} />
              </div>
            )}
            <button
              className={buttonClasses}
              disabled={
                (!paymentMethodIdFromParams && !hasPaymentMethodChanged) ||
                changePaymentMethodLoading
              }
              onClick={onChangePaymentMethod}
            >
              {changePaymentMethodLoading && <ButtonSpinner />}
              {paymentMethodIdFromParams
                ? 'Confirm Payment Method'
                : 'Change Payment Method'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
