import {getFormattedBsb} from '../core';

export class AUBsbNumber {
  private bsb: string;

  static maybeFromJSON(value: string): AUBsbNumber | null {
    try {
      return AUBsbNumber.fromJSON(value);
    } catch (e) {
      return null;
    }
  }

  static fromJSON(bsb: string): AUBsbNumber {
    return new AUBsbNumber(bsb);
  }

  constructor(code: string) {
    const bsb = code.trim().replace(/[^0-9]/g, '');

    if (bsb.length !== 6) {
      throw new Error(
        `Invalid BSB number. Expected 6 digits. Got: ${bsb.length}`
      );
    }

    this.bsb = bsb;
  }

  toFormatted() {
    return getFormattedBsb(this.bsb);
  }

  toJSON() {
    return this.bsb;
  }

  toString() {
    return this.bsb;
  }
}
