type NZBankAccountParts = 'bank' | 'branch' | 'account' | 'suffix';

type StringNZBankAccountParts = Record<NZBankAccountParts, string>;
type NumericNZBankAccountParts = Record<NZBankAccountParts, number>;

export const MAX_LENGTHS = {
  bank: 2,
  branch: 4,
  account: 8,
  suffix: 4,
} as const;

export const STANDARD_LENGTHS = {
  bank: 2,
  branch: 4,
  account: 7,
  suffix: 2,
} as const;

const ALGORITHMS = {
  A: {
    weights: [0, 0, 6, 3, 7, 9, 0, 0, 10, 5, 8, 4, 2, 1, 0, 0, 0, 0],
    mod: 11,
  },
  B: {
    weights: [0, 0, 0, 0, 0, 0, 0, 0, 10, 5, 8, 4, 2, 1, 0, 0, 0, 0],
    mod: 11,
  },
  C: {
    weights: [3, 7, 0, 0, 0, 0, 9, 1, 10, 5, 3, 4, 2, 1, 0, 0, 0, 0],
    mod: 11,
  },
  D: {weights: [0, 0, 0, 0, 0, 0, 0, 7, 6, 5, 4, 3, 2, 1, 0, 0, 0, 0], mod: 11},
  E: {weights: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 4, 3, 2, 0, 0, 0, 1], mod: 11},
  F: {weights: [0, 0, 0, 0, 0, 0, 0, 1, 7, 3, 1, 7, 3, 1, 0, 0, 0, 0], mod: 10},
  G: {weights: [0, 0, 0, 0, 0, 0, 0, 1, 3, 7, 1, 3, 7, 1, 0, 3, 7, 1], mod: 10},
  X: {weights: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], mod: 1},
} as const;

const BANK_ALGORITHM_SELECTION = {
  1: 'A or B',
  2: 'A or B',
  3: 'A or B',
  4: 'A or B',
  5: 'A or B',
  6: 'A or B',
  // 7 is unassigned
  8: 'D',
  10: 'A or B',
  11: 'A or B',
  12: 'A or B',
  13: 'A or B',
  14: 'A or B',
  15: 'A or B',
  16: 'A or B',
  17: 'A or B',
  18: 'A or B',
  19: 'A or B',
  20: 'A or B',
  21: 'A or B',
  22: 'A or B',
  23: 'A or B',
  24: 'A or B',
  25: 'F',
  // 26 is unassigned
  27: 'A or B',
  // 28-29 are unassigned
  30: 'A or B',
  31: 'X',
  // 32-37 are unassigned
  38: 'A or B',
  // Bank of china (88 is a lucky number in Chinese culture)
  88: 'A or B',
} satisfies Record<number, 'A or B' | 'F' | 'X' | 'D'>;

function validateViaChecksum(parts: NumericNZBankAccountParts) {
  const algorithmSelection =
    BANK_ALGORITHM_SELECTION[
      parts.bank as keyof typeof BANK_ALGORITHM_SELECTION
    ];

  if (!algorithmSelection) {
    throw new Error(`Bank number ${parts.bank} is not recognised`);
  }

  let algorithm: (typeof ALGORITHMS)[keyof typeof ALGORITHMS];
  if (algorithmSelection === 'A or B') {
    if (parts.account < 990000) {
      algorithm = ALGORITHMS.A;
    } else {
      algorithm = ALGORITHMS.B;
    }
  } else {
    algorithm = ALGORITHMS[algorithmSelection];
  }

  const fieldArray = toFixedLengthIntArray(parts);

  if (fieldArray.length !== algorithm.weights.length) {
    // This is just a sanity check.
    throw new Error(
      'Weights do  length does not match algorithm weights length'
    );
  }
  const weightedSum = fieldArray.reduce(
    (acc, value, index) => acc + value * algorithm.weights[index],
    0
  );

  if (weightedSum % algorithm.mod !== 0) {
    throw new Error('Checksum failed. Invalid bank account number.');
  }

  return true;
}

function toFixedLengthIntArray(parts: NumericNZBankAccountParts) {
  const {account, bank, branch, suffix} = toPaddedValues(parts);
  return `${bank}${branch}${account}${suffix}`.split('').map(Number);
}

function toPaddedValues(
  parts: NumericNZBankAccountParts,
  maxLengths: NumericNZBankAccountParts = MAX_LENGTHS
): StringNZBankAccountParts {
  return Object.fromEntries(
    Object.entries(parts).map(([key, value]) => {
      const stringedValue = value
        .toString()
        .padStart(maxLengths[key as NZBankAccountParts], '0');

      if (stringedValue.length !== maxLengths[key as NZBankAccountParts]) {
        throw new Error(
          `Invalid length for ${key}. Expected ${maxLengths[key as NZBankAccountParts]}. Got: ${stringedValue.length}`
        );
      }

      return [key as NZBankAccountParts, stringedValue] as const;
    })
  ) as StringNZBankAccountParts;
}

function validateForBNZ({suffix, account}: NumericNZBankAccountParts) {
  if (suffix > 99) {
    throw new Error('Suffix can only be up to 2 digits long');
  }

  if (account > 9999999) {
    throw new Error('Account can only be up to 7 digits long');
  }
}

/**
  Bank and branch numbers are updated monthly and available from
  paymentsnz.co.nz.

  This validates the bank account number structurally and via checksum but
  cannot stay up to date with the latest bank and branch numbers. This should
  be validated on the frontend (and probably loosely)

  NZ Bank Account Numbers are 16 digits long and are formatted as
  `bank-branch-account-suffix`.

  @see
  https://www.ird.govt.nz/-/media/project/ir/home/documents/digital-service-providers/software-providers/payroll-calculations-business-rules-specifications/payroll-calculations-and-business-rules-specification-2024-v1-1.pdf?modified=20230208203603&modified=20230208203603
  https://www.paymentsnz.co.nz/resources/industry-registers/bank-branch-register/
 */
export class NZBankAccountNumber {
  static maybeFromJSON(value: string) {
    try {
      return NZBankAccountNumber.fromJSON(value);
    } catch (e) {
      return null;
    }
  }

  static fromJSON(value: string) {
    const parts = value.trim().split('-');
    if (parts.length !== 4) {
      throw new Error(
        `Invalid bank account number. Expected 4 base parts. Got: ${parts.length}`
      );
    }

    const numeric = parts
      .map(part => Number(part))
      .filter(part => !Number.isNaN(part))
      .filter(Number.isInteger);

    if (numeric.length !== 4) {
      throw new Error(
        `Invalid bank account number. Expected 4 positive, numeric parts. Got: ${numeric.length}`
      );
    }

    const [bank, branch, account, suffix] = numeric;

    return new NZBankAccountNumber({bank, branch, account, suffix});
  }

  constructor(private props: NumericNZBankAccountParts) {
    validateViaChecksum(props);
    validateForBNZ(props);
  }

  toJSON() {
    const {account, bank, branch, suffix} = toPaddedValues(
      this.props,
      STANDARD_LENGTHS
    );

    return `${bank}-${branch}-${account}-${suffix}`;
  }

  /*
   * BNZ Grift requires bbbbbbnnnnnnnss or bbbbbbnnnnnnnsss so we format down our padded values and verify no data is lost
   */
  toGriftFilesFormat() {
    const {account, bank, branch, suffix} = toPaddedValues(this.props);

    return `${bank}${branch}${account.substring(1)}${suffix.substring(1)}`;
  }

  toBankBranchCode() {
    const {bank, branch} = toPaddedValues(this.props);
    return `${bank}-${branch}`;
  }
}
