import React from 'react';
import {goBack} from 'lib/navigation/routes';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {PaymentAmountForm} from 'features/setup/components/PaymentAmountForm';
import {useAccountProvider} from '../../providers/AccountProvider';

type PaymentAmountStepProps = {
  onNext: ({amountInCents}: {amountInCents: string}) => void;
  accountExternalId: string;
  accountType: string;
  verificationCode?: string;
};

export const PaymentAmountStep: React.FC<PaymentAmountStepProps> = ({
  onNext,
}) => {
  const billerConfig = useBillerConfig();
  const {account} = useAccountProvider();

  const paymentConfig = billerConfig.getAccountPaymentConfig({
    ...account,
    setupBreakdown: account.setupBreakdown ?? [],
    nextInstalmentAmount: account.targetInstalments?.[0]?.amount,
  });

  const {minAmount, maxAmount, defaultAmount, lockedAmount} = paymentConfig;

  return (
    <div className="my-8">
      <PaymentAmountForm
        debbieTitle={
          lockedAmount
            ? 'Please confirm the payment amount'
            : 'How much do you want to pay now?'
        }
        heading="Pay Now"
        description={`${lockedAmount ? 'Confirm the amount' : 'Enter an amount'} to pay now`}
        minAmount={minAmount}
        maxAmount={maxAmount}
        lockedAmount={lockedAmount}
        defaultAmount={defaultAmount}
        onSubmit={values => {
          onNext({amountInCents: values.amountInCents.toString()});
        }}
        onClickBack={goBack}
      />
    </div>
  );
};
