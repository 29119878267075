import React from 'react';

import {Routes, Route, Navigate} from 'react-router-dom';

import {history, RouteProps} from 'lib/navigation/routes';

import {Payment} from 'features/payment/Payment';
import {InstalmentPlan} from 'features/instalment-plan/InstalmentPlan';
import {Setup as SetupV3} from 'features/setup/Setup';
import {Infringement} from 'features/infringement/Infringement';
import {NotFound} from 'features/navigation/NotFound';
import {CustomRouter} from 'lib/navigation/CustomerRouter';

import {Home} from 'features/home/Home';
import {Profile} from 'features/profile/Profile';
import {AddPaymentMethod} from 'features/profile/pages/AddPaymentMethod';
import {EditProfile} from 'features/profile/pages/EditProfile';
import {ErrorBoundary} from 'features/navigation/ErrorBoundary';
import {Support} from 'features/support/Support';
import {ScrollToTop} from 'components/atoms/ScrollTop';
import {NotificationSetting} from 'features/notification/NotificationSetting';
import {AnonymousPayment} from 'features/anonymous-payment/AnonymousPayment';
import {useBillerConfig} from '../../lib/appConfig/useBillerConfig';
import {useConsumerAppConfig} from '../../lib/appConfig/ConsumerAppConfig';
import {getFlowRoutes} from '../../flows/getflowRoutes';
import {RequireAuth} from '../../lib/authv2/RequireAuth';
import {BasePage} from 'components/layout/index';
import {RedirectManager} from '../../lib/navigation/RedirectManager';
import {auth} from '../../lib/authv2/auth';
import {Login} from '../login/Login';

const ROUTES: RouteProps[] = [
  {
    element: (
      <RequireAuth>
        <BasePage>
          <SetupV3 />
        </BasePage>
      </RequireAuth>
    ),
    path: '/biller/:slug/setup/*',
  },
  {
    element: (
      <BasePage>
        <Infringement />
      </BasePage>
    ),
    path: '/biller/:slug/infringements/*',
  },
  {
    element: (
      <BasePage>
        <AnonymousPayment />
      </BasePage>
    ),
    path: '/biller/:slug/pay-now/*',
  },
  {
    element: (
      <BasePage>
        <Support />
      </BasePage>
    ),
    path: '/biller/:slug/support',
  },
  {
    element: (
      <RequireAuth>
        <BasePage>
          <InstalmentPlan />
        </BasePage>
      </RequireAuth>
    ),
    path: '/biller/:slug/instalment-plan/:instalmentPlanId/*',
  },
  {
    element: (
      <RequireAuth>
        <BasePage>
          <Profile />
        </BasePage>
      </RequireAuth>
    ),
    path: '/biller/:slug/profile/*',
  },
  {
    element: (
      <RequireAuth>
        <BasePage>
          <EditProfile />
        </BasePage>
      </RequireAuth>
    ),
    path: '/biller/:slug/profile/edit',
  },
  {
    element: (
      <RequireAuth>
        <BasePage>
          <AddPaymentMethod />
        </BasePage>
      </RequireAuth>
    ),
    path: '/biller/:slug/profile/add-payment-method',
  },
  {
    element: (
      <RequireAuth>
        <BasePage>
          <Home />
        </BasePage>
      </RequireAuth>
    ),
    path: '/biller/:slug',
  },
  {
    element: (
      <RequireAuth>
        <BasePage>
          <Payment />
        </BasePage>
      </RequireAuth>
    ),
    path: '/biller/:slug/payment/:paymentId',
  },
  {
    element: (
      <RequireAuth>
        <BasePage>
          <NotificationSetting />
        </BasePage>
      </RequireAuth>
    ),
    path: '/biller/:slug/notification',
  },
];

export const Navigation: React.FC = () => {
  const billerConfig = useBillerConfig();
  const appConfig = useConsumerAppConfig();
  const slug = billerConfig.billerSlug;

  const isLoggedIn = auth.useIsLoggedIn();

  return (
    <ErrorBoundary>
      <CustomRouter history={history}>
        <RedirectManager>
          <ScrollToTop />
          <Routes>
            {getFlowRoutes({appConfig, billerConfig})}
            {!isLoggedIn && (
              <Route
                path="/biller/:slug"
                element={<Navigate to={`/biller/${slug}/setup`} />}
              />
            )}
            {isLoggedIn ? (
              <Route
                path="/biller/:slug/login"
                element={<Navigate to={`/biller/${slug}`} />}
              />
            ) : (
              <Route path="/biller/:slug/login" element={<Login />} />
            )}
            {ROUTES.map(route => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
            <Route
              path="*"
              element={
                <BasePage>
                  <NotFound />
                </BasePage>
              }
            />
          </Routes>
        </RedirectManager>
      </CustomRouter>
    </ErrorBoundary>
  );
};
