import {FC, ReactNode} from 'react';
import {Controller} from 'react-hook-form';
import {useRegisterField} from '../hooks/useRegisterField';
import {FormField} from './FormField';
import {InputDate, InputDateProps} from './InputDate';
import {AbsoluteDate} from 'payble-shared';

export interface FormInputDateProps extends Omit<InputDateProps, 'onChange'> {
  label?: ReactNode;
  name: string;
  minDate?: AbsoluteDate;
  maxDate?: AbsoluteDate;
  onChange?: (date?: AbsoluteDate | null) => void;
  className?: string;
}

export const FormInputDate: FC<FormInputDateProps> = ({
  label,
  name,
  size,
  minDate,
  maxDate,
  onChange: customOnChange,
  className,
}) => {
  const {id, form} = useRegisterField(name);

  return (
    <FormField
      id={id}
      name={name}
      label={label}
      size={size}
      className={className}
    >
      <Controller
        control={form.control}
        name={name}
        render={({formState, field: {onChange, value, disabled}}) => {
          return (
            <InputDate
              id={id}
              onChange={(...args) => {
                onChange(...args);
                customOnChange?.(...args);
              }}
              value={value}
              size={size}
              disabled={disabled || formState.isSubmitting}
              minDate={minDate}
              maxDate={maxDate}
            />
          );
        }}
      />
    </FormField>
  );
};
