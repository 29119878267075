import {Route, useLocation} from 'react-router';
import * as actualFlows from './definitions';
import {FlowDefinition, StepDefinition} from './FlowDefinition';
import {BillerConfig} from 'payble-shared';
import {ConsumerAppConfig} from 'payble-shared/src/app-config/ConsumerAppConfig';
import {fromQueryString} from 'payble-api-client';
import {ErrorPage} from 'features/navigation/Error';
import {RequireAuth} from '../lib/authv2/RequireAuth';

function StepContainer(props: {
  flow: FlowDefinition;
  stepName: string;
  step: StepDefinition;
  config: {
    appConfig: ConsumerAppConfig;
    billerConfig: BillerConfig;
  };
}) {
  const {step, config} = props;
  const requiresAuth = !!step.requiresAuth?.(config);

  const stepData = step
    .schema(config)
    .safeParse(fromQueryString(useLocation().search.substring(1)));

  if (!stepData.success) {
    console.error('Failed to parse step data', stepData.error);
    return <ErrorPage showNavbar={false} />;
  }

  const Step = step.component;

  return (
    <RequireAuth when={requiresAuth}>
      <Step state={stepData.data} config={config} requiresAuth={requiresAuth} />
    </RequireAuth>
  );
}

export function getFlowRoutes(config: {
  billerConfig: BillerConfig;
  appConfig: ConsumerAppConfig;
}) {
  const flows: Record<string, FlowDefinition> = actualFlows;

  return Object.entries(flows).flatMap(([flowName, flowDefinition]) => {
    if (flowDefinition.available && !flowDefinition.available(config)) {
      return null;
    }

    return Object.entries(flowDefinition.steps).map(
      ([stepName, stepDefinition]) => {
        const stepPath = stepDefinition.path ?? stepName;
        return (
          <Route
            key={`${flowName}-${stepName}`}
            path={`/biller/:slug${flowDefinition.path}${stepPath.length ? `/${stepPath}` : ''}`}
            element={
              <StepContainer
                config={config}
                flow={flowDefinition}
                stepName={stepName}
                step={stepDefinition}
              />
            }
          ></Route>
        );
      }
    );
  });
}
