import {z} from 'zod';
import {accountResponse} from './account';
import {ConsumerContactResponseSchema, paymentMethodResponse} from './contact';
import {zAbsoluteDateRange, zISODate, zNZBankAccountNumber} from './generic';
import {instalmentPlanResponse} from './plan';

export const addPaymentMethodResponse = z.object({
  contact: ConsumerContactResponseSchema,
  paymentMethodId: z.string(),
  updatedPlans: z.array(instalmentPlanResponse),
  paymentMethod: paymentMethodResponse,
});

export type CheckoutPreview = z.output<typeof CheckoutPreviewSchema>;
export const CheckoutPreviewSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('payment'),
    formattedTotal: z.string(),
    date: z.string().optional(),
  }),
  z.object({
    type: z.literal('payment_with_surcharge'),
    prettyRate: z.string(),
    formattedSurcharge: z.string(),
    formattedTotal: z.string(),
    formattedPrincipal: z.string(),
    date: z.string().optional(),
  }),
  z.object({
    type: z.literal('plan'),
    schedule: z.array(
      z.discriminatedUnion('type', [
        z.object({
          type: z.literal('headline'),
          content: z.string(),
        }),
        z.object({
          type: z.literal('simple_payment_schedule'),
          header: z.string().optional(),
          rows: z.array(
            z.object({
              style: z.enum(['row', 'danger-row', 'header', 'footer']),
              l: z.string(),
              r: z.string(),
            })
          ),
        }),
        z.object({
          type: z.literal('important_note'),
          content: z.string(),
        }),
        z.object({
          type: z.literal('light_note'),
          content: z.string(),
        }),
      ])
    ),
  }),
]);

export const ConsumerPaymentMethodSummary = z.object({
  paymentMethodId: z.string().uuid(),
  type: z.enum(['card', 'direct_debit', 'nz_direct_debit']),
  imageURL: z.string(),
  disabled: z
    .object({
      reason: z.string().optional(),
    })
    .optional(),
  /** Debit/Credit card, Direct Debit */
  label: z.string(),
  /** The bank name typically */
  subLabel: z.string().optional(),
  /**
   * Masked card number
   * Expiry date
   *
   * Full BSB
   * Masked account number
   */
  lines: z.array(z.string()),
});

export const CreatePaymentMethodRequest = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('card'),
    brand: z.string(),
    expiryMonth: z.number().int().min(1).max(12),
    expiryYear: z.number().int(),
    holderName: z.string(),
    last4: z.string(),
    numberToken: z.string(),
    numberTokenHmac: z.string(),
    referenceNumber: z.string(),
  }),
  z.object({
    type: z.literal('direct_debit'),
    accountName: z.string(),
    accountNumber: z.string(),
    bsb: z.string(),
  }),
  z.object({
    type: z.literal('nz_direct_debit'),
    accountName: z.string(),
    accountNumber: zNZBankAccountNumber,
  }),
]);

export type AddPaymentMethodResponse = z.infer<typeof addPaymentMethodResponse>;

export const paymentStatusSchema = z.enum([
  'processing',
  'cancelled',
  'succeeded',
  'failed',
]);

export const paymentModeSchema = z.enum([
  'pay-instalment',
  'pay-instalment-plan-remainder',
  'pay-in-full',
  'pay-amount',
]);

export const paymentResponse = z.object({
  id: z.string(),
  mode: paymentModeSchema,
  currency: z.string(),
  amount: z.number(),
  accountId: z.string(),
  account: accountResponse.optional(),
  instalmentId: z.string().nullable(),
  instalmentPlanId: z.string().nullable(),
  paymentMethodId: z.string(),
  testMode: z.boolean(),
  status: paymentStatusSchema,
  refundStatus: paymentStatusSchema.nullable(),
  gateway: z.string(),
  gatewayTransactionId: z.string().nullable(),
  processedAt: zISODate.nullable(),
  succeededAt: zISODate.nullable(),
  failedAt: zISODate.nullable(),
  refundedAt: zISODate.nullable(),
  createdAt: zISODate,
});

export type PaymentResponse = z.infer<typeof paymentResponse>;

export const filterPaymentsSchema = z.object({
  accountId: z.string().optional(),
  contactId: z.string().optional(),
  status: z.array(paymentStatusSchema).optional(),
  createdAt: zAbsoluteDateRange.nullable().optional(),
  failedAt: zAbsoluteDateRange.nullable().optional(),
  succeededAt: zAbsoluteDateRange.nullable().optional(),
  processedAt: zAbsoluteDateRange.nullable().optional(),
  settlementDate: zAbsoluteDateRange.nullable().optional(),
});

export type FilterPaymentsSchema = NonNullable<
  z.infer<typeof filterPaymentsSchema>
>;
