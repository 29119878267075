import React, {useEffect} from 'react';
import NumberFormat from 'react-number-format';
import {useParams} from 'react-router';

import {HomeIcon} from '@heroicons/react/20/solid';
import {Loading} from 'components/atoms/Loading';
import {Debbie} from 'components/organisms/Debbie';
import {Timeline} from '../components/Timeline';
import {InstalmentPlanMenu} from '../components/InstalmentPlanMenu';

import {getBillerSlugFromUrl} from 'lib/url';
import {navigate} from 'lib/navigation/routes';
import {PaymentMethodCompact} from '../components/PaymentMethodCompact';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {RolloverNotice} from 'components/atoms/RolloverNotice';
import {getInstalmentPlanHeading} from 'features/instalment-plan/getInstalmentPlanHeading';
import {capitalize, formatToDollars, toTitleCase} from 'payble-shared';
import {getYearlyRollOverStatusByBillerSlug} from 'payble-shared/src/biller-config/handlers';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';
import {useAPIQuery} from 'lib/api';
import {auth} from '../../../lib/authv2/auth';

export const View: React.FC = () => {
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const slug = getBillerSlugFromUrl();
  const hasYearlyRollOver = getYearlyRollOverStatusByBillerSlug(slug);
  const paymentMethods = auth.usePaymentMethods();

  useEffect(() => {
    document.title = 'Payble - Payment Plan';
  }, []);

  const billerConfig = useBillerConfig();

  const {
    isLoading: loading,
    error,
    data: instalmentPlan,
  } = useAPIQuery('getInstalmentPlan', {
    data: instalmentPlanId ? {instalmentPlanId} : undefined,
  });

  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;

  // Handle loading states
  if (loading) {
    return <Loading />;
  }

  if (error)
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops! Payment plan not found!"
        debbieMessage="It seems like something went wrong with the plan you're looking for. Don't worry we got you. You can click below to go back home and then look for all the plans available."
        onClick={() => {
          navigate('/biller/:slug', {slug});
        }}
        buttonText="Go Home"
      />
    );

  if (!instalmentPlan?.id) return <>No payment plan found</>;

  const currentPaymentMethod = paymentMethods.find(
    p => p.id === instalmentPlan?.paymentMethodId
  );

  const isPaymentMethodActive = currentPaymentMethod?.status === 'active';

  const instalmentPlanHeading = getInstalmentPlanHeading(instalmentPlan);

  const isConsumerFacingPlan = instalmentPlan.mode !== 'pay-on-y';

  return (
    <div className="relative">
      <Debbie
        title={`Manage your ${instalmentPlanHeading} ${
          isConsumerFacingPlan
            ? `plan for #${instalmentPlan.account?.externalId}`
            : `for #${instalmentPlan.account?.externalId}`
        }`}
        message={`You can track and manage your ${instalmentPlanHeading} plan here`}
      />

      <div className="flex mt-5 mb-2">
        <div className="flex-1">
          <nav className="flex" aria-label="Breadcrumb">
            <ol
              role="list"
              className="flex px-6 space-x-4 bg-white rounded-md shadow"
            >
              <li className="flex">
                <div className="flex items-center">
                  <button
                    onClick={() => navigate('/biller/:slug', {slug})}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="flex-shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </button>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <button
                    onClick={() =>
                      navigate(
                        '/biller/:slug/instalment-plan/:instalmentPlanId',
                        {slug, instalmentPlanId}
                      )
                    }
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    Payment plan
                  </button>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="flex pl-4">
          <InstalmentPlanMenu
            slug={slug}
            instalmentPlan={instalmentPlan}
            isPaymentMethodActive={isPaymentMethodActive}
            canUpdateInstalmentAmount={instalmentPlan.canUpdateInstalmentAmount}
          />
        </div>
      </div>

      <div className="mt-5 overflow-hidden shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {`${toTitleCase(instalmentPlanHeading)} ${
                instalmentPlan.account.externalId
              }`}
            </h3>
            <p className="mt-1 text-sm text-gray-500">{`${capitalize(
              billerConfig.getAccountTypeTitle(instalmentPlan.account.type)
            )} for ${instalmentPlan.account.description}`}</p>
          </div>
          <div className="grid grid-cols-3 mt-4 mb-8 text-sm gap-y-2 gap-x-4">
            {currentPaymentMethod && (
              <>
                <div className="relative font-semibold text-gray-500 top-2">
                  Payment Method
                </div>
                <div className="relative col-span-2">
                  <PaymentMethodCompact paymentMethod={currentPaymentMethod} />
                  {!isPaymentMethodActive && (
                    <button
                      onClick={() => {
                        if (instalmentPlan) {
                          navigate(
                            '/biller/:slug/instalment-plan/:instalmentPlanId/change-payment-method',
                            {
                              instalmentPlanId: instalmentPlan.id,
                              slug,
                            }
                          );
                        }
                      }}
                      className="absolute px-4 py-2 text-xs text-gray-800 bg-gray-100 border-transparent rounded-lg -right-2 hover:bg-gray-200 top-1 focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-gray-200"
                    >
                      Change
                    </button>
                  )}
                </div>
              </>
            )}
            {instalmentPlan.frequency && (
              <>
                <div className="font-semibold text-gray-500">Frequency</div>
                <div className="col-span-2">
                  {toTitleCase(instalmentPlan.frequency)}
                </div>
              </>
            )}
            <div className="font-semibold text-gray-500">Total</div>
            <div className="col-span-2">
              <NumberFormat
                value={formatToDollars(instalmentPlan.amount)}
                displayType={'text'}
                thousandSeparator={true}
                decimalSeparator={'.'}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={'$'}
              />
            </div>
            <div className="font-semibold text-gray-500">Paid</div>
            <div className="col-span-2">
              <NumberFormat
                value={formatToDollars(instalmentPlan.amountPaid)}
                displayType={'text'}
                thousandSeparator={true}
                decimalSeparator={'.'}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={'$'}
              />
            </div>
            <div className="font-semibold text-gray-500">Remaining</div>
            <div className="relative col-span-2">
              <NumberFormat
                value={formatToDollars(instalmentPlan.amountDue)}
                displayType={'text'}
                thousandSeparator={true}
                decimalSeparator={'.'}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={'$'}
              />
              <button
                disabled={instalmentPlan.instalments.length === 0}
                onClick={() =>
                  navigate(
                    '/biller/:slug/instalment-plan/:instalmentPlanId/schedule',
                    {slug, instalmentPlanId}
                  )
                }
                className="absolute px-4 py-2 text-xs text-gray-800 bg-gray-100 border-transparent rounded-lg -right-2 hover:bg-gray-200 -top-1 focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-gray-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                See more
              </button>
            </div>
          </div>

          <h3 className="pb-3 mb-0 text-base font-medium leading-6 text-gray-900 border-b-2 border-b-gray-100">
            Activity
          </h3>
          <RolloverNotice
            showNotice={hasYearlyRollOver && !!instalmentPlan?.completedAt}
          />
          <Timeline
            instalmentPlan={instalmentPlan}
            isPaymentMethodActive={isPaymentMethodActive}
          />
        </div>
      </div>
    </div>
  );
};
