import React, {useEffect} from 'react';
import {z} from 'zod';
import {zPhoneNumber} from 'payble-api-client/schemas';
import {HomeIcon} from '@heroicons/react/20/solid';
import {getBillerSlugFromUrl} from 'lib/url';
import {ProfileMenu} from '../components/ProfileMenu';
import {navigate} from 'lib/navigation/routes';
import {Debbie} from 'components/organisms/Debbie';
import {Form, MaxContainer} from 'payble-ui';
import {useAPIMutation} from 'lib/api';
import {auth} from '../../../lib/authv2/auth';
import {useConsumerAppConfig} from '../../../lib/appConfig/ConsumerAppConfig';

export const EditProfile: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const contact = auth.useContact();
  const {mobileNumberPlaceholder} = useConsumerAppConfig();

  useEffect(() => {
    document.title = 'Payble - Edit Profile';
  }, []);

  const updateContact = useAPIMutation('updateContact');

  let profileName = 'Your';
  if (contact?.givenName) {
    profileName = contact?.givenName;
    profileName = profileName.endsWith('s')
      ? `${profileName}'`
      : `${profileName}'s`;
  }
  const titleOptions = [
    {value: '', label: ''},
    {value: 'Dr', label: 'Dr'},
    {value: 'Miss', label: 'Miss'},
    {value: 'Mr', label: 'Mr'},
    {value: 'Mrs', label: 'Mrs'},
    {value: 'Mx', label: 'Mx'},
    {value: 'Prof', label: 'Prof'},
  ];
  return (
    <MaxContainer>
      <Debbie
        title={'Edit your details'}
        message="Update your contact details here. These are used to send communication and match your account."
      />

      <div className="flex mt-5 mb-2">
        <div className="flex-1">
          <nav className="flex" aria-label="Breadcrumb">
            <ol
              role="list"
              className="flex px-6 space-x-4 bg-white rounded-md shadow"
            >
              <li className="flex">
                <div className="flex items-center">
                  <button
                    onClick={() =>
                      navigate('/biller/:slug', {slug: billerSlug})
                    }
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="flex-shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </button>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <button
                    onClick={() =>
                      navigate('/biller/:slug/profile', {
                        slug: billerSlug,
                      })
                    }
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {profileName} profile
                  </button>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="flex pl-4">
          <ProfileMenu slug={billerSlug} />
        </div>
      </div>

      <div className="mt-5 overflow-hidden shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Profile Information
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              We use these details to contact you and link your payments with
              your account.
            </p>
            <Form
              schema={z.object({
                title: z.union([z.literal(''), z.string()]).optional(),
                givenName: z.string(),
                familyName: z.string(),
                sendingEmailReceipts: z.boolean().optional(),
                email: z.string().email().optional(),
                mobile: zPhoneNumber.optional(),
              })}
              defaultValues={{
                title: contact.title,
                givenName: contact.givenName,
                familyName: contact.familyName,
                email: contact.email,
                mobile: contact.mobile,
                sendingEmailReceipts: contact.sendingEmailReceipts,
              }}
              onSubmit={async data => {
                const {
                  title,
                  email,
                  mobile,
                  givenName,
                  familyName,
                  sendingEmailReceipts,
                } = data;
                await updateContact.mutateAsync({
                  title,
                  email,
                  mobile,
                  givenName,
                  familyName,
                  sendReceipt: sendingEmailReceipts,
                  source: 'profile',
                });
                navigate('/biller/:slug/profile', {slug: billerSlug});
              }}
              className="mt-4"
            >
              <Form.Select
                name="title"
                id="title"
                label="Title"
                options={titleOptions}
              />
              <Form.Input
                type="text"
                name="givenName"
                id="givenName"
                placeholder="Given name"
                label="Given Name"
              />
              <Form.Input
                type="text"
                name="familyName"
                id="familyName"
                placeholder="Family name"
                label="Family Name"
              />
              {contact.authMethod === 'mobile' && (
                <Form.Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="you@example.com"
                  label="Email"
                />
              )}
              {contact.authMethod === 'email' && (
                <Form.InputPhone
                  name="mobile"
                  id="mobile"
                  placeholder={mobileNumberPlaceholder}
                  label="Mobile number"
                  className="text-left"
                />
              )}
              <Form.Switch
                name="sendingEmailReceipts"
                id="sendingEmailReceipts"
                label="Send payment receipts and other important payment related information to my email"
                labelPosition="between"
              />
              <Form.SubmitButton>Save profile</Form.SubmitButton>
            </Form>
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={() => {
          navigate('/biller/:slug/profile', {
            slug: billerSlug,
          });
        }}
        className="inline-flex items-center justify-center w-full mt-6 text-center text-blue-600 transition hover:text-blue-700"
      >
        Go back
      </button>
    </MaxContainer>
  );
};
