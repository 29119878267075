import {ReceiptLayoutItemSchema} from 'payble-api-client/schemas/checkout';
import {z} from 'zod';

interface ReceiptViewProps {
  lines: z.input<typeof ReceiptLayoutItemSchema>[];
}

export const ReceiptView: React.FC<ReceiptViewProps> = ({lines}) => {
  return (
    <>
      {lines.map((line, idx) => {
        if (line.type === 'category_header') {
          return (
            <h4
              key={idx}
              className="mb-2 ml-2 text-sm font-bold text-left sm:text-lg"
            >
              {line.content}
            </h4>
          );
        }

        if (line.type === 'dictionary_card') {
          return (
            <div key={idx}>
              {line.items.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex py-2 text-xs font-semibold sm:text-sm"
                  >
                    <div className="px-2 basis-6/12">{item.label}</div>
                    <div className="px-2 text-right basis-6/12">
                      {item.value}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }

        const __: never = line;

        throw new Error(`Unhandled line type: ${line}`);
      })}
    </>
  );
};
