import {useEffect} from 'react';
import {getWelcomeDebbieContent} from 'features/setup/helpers';
import {useGetAccountTypeInfo} from 'features/setup/hooks/useGetAccountTypeInfo';
import {useSetupSearchParams} from 'features/setup/hooks/useSetupSearchParams';
import {useSetupNavigate} from 'features/setup/hooks/useSetupNavigate';
import {useInfringementNavigate} from 'features/infringement/hooks/useInfringementNavigate';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {useAPIMutation} from 'lib/api';
import {Loading} from 'payble-ui';
import {LookupAccount} from 'features/setup/components/LookupAccount';
import {AccountWarningModal} from 'features/setup/components/AccountWarningModal';
import {ConfirmLinkAccount} from 'features/setup/components/ConfirmLinkAccount';
import {useConsumerAppConfig} from 'lib/appConfig/ConsumerAppConfig';
import {Debbie} from 'components/organisms/Debbie';
import {getAccount} from 'lib/account/getAccount';
import {auth} from '../../../../lib/authv2/auth';

const SUPPORTS_PAYMENT_CATEGORY = 'notice';

type AccountLookupStepProps = {
  onNext: ({
    accountType,
    accountExternalId,
    verificationCode,
  }: {
    accountType: string;
    accountExternalId: string;
    verificationCode?: string;
  }) => void;
  authenticated?: boolean;
};

export const AccountLookupStep: React.FC<AccountLookupStepProps> = ({
  onNext,
  authenticated,
}) => {
  const accountTypeInfo = useGetAccountTypeInfo();
  const contact = auth.useMaybeContact();
  const billerConfig = useBillerConfig();

  const {
    patch,
    accountExternalId,
    verificationCode,
    lookingUpAnother,
    accountType,
  } = useSetupSearchParams();

  const {
    loading: loadingAccount,
    data: account,
    error,
    requiresVerification,
  } = getAccount({
    accountType,
    externalId: accountExternalId,
    verificationCode: verificationCode,
    billerSlug: billerConfig.billerSlug,
    anonymous: !authenticated,
  });

  const navigate = useSetupNavigate();
  const infringementNavigate = useInfringementNavigate();

  const biller = useConsumerAppConfig().biller;

  useEffect(() => {
    if (accountType === 'infringements') {
      infringementNavigate('/confirm-payment', undefined, undefined, true);
    }
  }, [accountType]);

  const {mutateAsync: linkAccount, isPending} = useAPIMutation(
    'addContactToAccount',
    {
      query: {
        onSuccess() {
          if (!account) {
            throw new Error(
              'Account not found unable to invoke onNext in AccountLookupStep'
            );
          }

          onNext({
            accountType: account.type,
            accountExternalId: account.externalId,
            verificationCode,
          });
        },
        onError(e) {
          navigate('/biller/:slug');
          throw e;
        },
      },
    }
  );

  const loading = loadingAccount || isPending;

  const accountCanSetupInstallmentPlan = (balance: number) => {
    return billerConfig.canPayZeroBalanceAccount || balance > 0;
  };

  const {title, message} = contact
    ? getWelcomeDebbieContent({
        biller,
        includeGetStarted: !loading && !account,
        contact,
        account,
        externalIdLabel: accountTypeInfo.externalIdLabel,
        canPayZeroBalanceAccount: billerConfig.canPayZeroBalanceAccount,
      })
    : {
        title: 'Welcome',
        message: `Please enter your ${accountTypeInfo.externalIdLabel.toLowerCase()} below to get started`,
      };

  const showLookupForm =
    lookingUpAnother ||
    (!loading && !account) ||
    (account && !accountCanSetupInstallmentPlan(account.amountOwing));

  const showConfirmLinking =
    !lookingUpAnother &&
    !loading &&
    account &&
    accountCanSetupInstallmentPlan(account.amountOwing);

  const onSubmit = () => {
    if (!account) {
      throw new Error(
        `No account found when to move next step in AccountLookupStep authenticated: ${authenticated}`
      );
    }

    authenticated
      ? linkAccount({
          accountExternalId: account.externalId,
          accountType: account.type,
          verificationCode,
        })
      : onNext({
          accountType: account.type,
          accountExternalId: account.externalId,
          verificationCode,
        });
  };

  return (
    <>
      {showLookupForm ? (
        <>
          <Debbie title={title} message={message} />
          <LookupAccount
            loading={loading}
            error={error}
            requiresVerification={requiresVerification}
            onSubmit={values => {
              patch({...values, lookingUpAnother: ''});
            }}
            supportedPaymentCategory={SUPPORTS_PAYMENT_CATEGORY}
          />
        </>
      ) : null}

      {loading ? <Loading /> : null}

      {showConfirmLinking ? (
        <>
          <Debbie title="Is this the property you were looking for?" />
          <AccountWarningModal onConfirm={onSubmit} />
          <ConfirmLinkAccount
            account={account}
            onLinkAccount={onSubmit}
            onLookupAnotherAccount={() => {
              patch({lookingUpAnother: '1'});
            }}
          />
        </>
      ) : null}
    </>
  );
};
