import {z} from 'zod';
import {FormConfig} from '../../types';

export const sinch = {
  title: 'Sinch SMS',
  configType: 'biller',

  validation: z.object({
    senderId: z.string().min(1, 'Sender ID is required'),
    apiKey: z.string().min(1, 'API Key is required'),
    apiSecret: z.string().min(1, 'API Secret is required'),
    senderIdType: z.enum(['INTERNATIONAL', 'ALPHANUMERIC', 'SHORTCODE']),
    test: z.boolean().optional(),
  }),

  form: [
    {
      type: 'text',
      name: 'apiKey',
      label: 'API Key (Basic)',
      description:
        'https://support.messagemedia.com/hc/en-us/articles/4413627066383-Creating-New-API-Credentials',
    },
    {
      type: 'password',
      name: 'apiSecret',
      label: 'API Secret (Basic)',
    },
    {
      type: 'text',
      name: 'senderId',
      label: 'Sender ID',
      description: 'The sender ID provisioned for the biller',
    },
    {
      type: 'dropdown',
      label: 'Sender ID Type',
      name: 'senderIdType',
      options: ['INTERNATIONAL', 'ALPHANUMERIC', 'SHORTCODE'],
      description:
        'https://support.messagemedia.com/hc/en-us/articles/4413635760527-Messaging-API',
    },
  ],
} satisfies FormConfig;
