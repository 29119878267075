import {z} from 'zod';
import {FormConfig} from '../../types';

export const international_sinch = {
  title: 'International Sinch',
  configType: 'global',

  validation: z.object({
    apiKey: z.string().min(1, 'API Key is required'),
    apiSecret: z.string().min(1, 'API Secret is required'),

    callingCodes: z.array(
      z.object({
        callingCode: z.string().min(1, 'Calling Code is required'),
        senderId: z.string().min(1, 'Sender ID is required'),
        senderIdType: z.enum(['INTERNATIONAL', 'ALPHANUMERIC', 'SHORTCODE']),
      })
    ),
  }),

  form: [
    {
      type: 'text',
      name: 'apiKey',
      label: 'International API Key (Basic)',
      description:
        'https://support.messagemedia.com/hc/en-us/articles/4413627066383-Creating-New-API-Credentials',
    },
    {
      type: 'password',
      name: 'apiSecret',
      label: 'International API Secret (Basic)',
    },
    {
      type: 'list',
      fields: [
        {
          type: 'text',
          name: 'callingCode',
          label: 'Calling Code',
          description:
            '(e.g. 61 for Australia). Do not provide the + (plus sign). This is used to match the country code/calling code of the recipient phone number.',
        },
        {
          type: 'text',
          name: 'senderId',
          label: 'Sender ID',
        },
        {
          type: 'dropdown',
          label: 'Sender ID Type',
          name: 'senderIdType',
          options: ['INTERNATIONAL', 'ALPHANUMERIC', 'SHORTCODE'],
          description:
            'https://support.messagemedia.com/hc/en-us/articles/4413635760527-Messaging-API',
        },
      ],
      label: 'International Sender IDs',
      name: 'callingCodes',
    },
  ],
} satisfies FormConfig;
