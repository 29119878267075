import {GetAccountByExternalIdResponse} from 'lib/account/useGetAccountFromSearchParams';
import {possessive} from 'payble-shared';

type GetWelcomeDebbieContent = {
  includeGetStarted: boolean;
  externalIdLabel: string;
  canPayZeroBalanceAccount: boolean;
  biller: {
    name: string;
  };
  contact?: {givenName: string | null; familyName: string | null} | null;
  account?: GetAccountByExternalIdResponse | null;
};

export const getWelcomeDebbieContent = ({
  includeGetStarted,
  externalIdLabel,
  canPayZeroBalanceAccount,
  biller,
  contact,
  account,
}: GetWelcomeDebbieContent) => {
  let title = 'Welcome';
  let message = '';

  if (biller) {
    message = `I'm happy to introduce you to ${possessive(
      biller.name
    )} new flexible payment experience.`;
  }
  if (includeGetStarted) {
    message += ` Please enter your ${externalIdLabel.toLowerCase()} below to get started`;
  }

  if (account && account.amountOwing <= 0 && !canPayZeroBalanceAccount) {
    title = 'Great news';
    message = 'It looks like your account is already fully paid.';

    if (contact?.givenName) {
      title += ` ${contact.givenName}!`;
    }
  } else if (contact?.familyName && contact?.givenName) {
    title += ` ${contact.givenName} ${contact.familyName}`;
  }

  return {title, message};
};
