import {CheckoutStep} from 'payble-api-client/schemas/checkout';
import {APIOutput} from 'payble-api-client/utils/typeHelpers';
import {useFlowNavigate} from '../../flows/flows';
import {useNavigate} from 'react-router';

export const useCheckoutNavigate = ({
  next = useFlowNavigate(),
  navigate = useNavigate(),
  checkout,
  currentStep,
  checkoutId,
}: {
  next?: ReturnType<typeof useFlowNavigate>;
  navigate?: ReturnType<typeof useNavigate>;
  checkout: APIOutput<'consumer', 'getCheckout'> | null;
  checkoutId: string;
  currentStep:
    | APIOutput<'consumer', 'getCheckout'>['steps'][number]['step']
    | 'NONE';
}) => {
  const stepsToNavigation: {
    [key in CheckoutStep['step']]: () => void;
  } = {
    create_payment_method: () => {
      next('checkout.create-payment-method', {checkoutId});
    },
    select_payment_method: () => {
      next('checkout.select-payment-method', {checkoutId});
    },
    preview: () => {
      next('checkout.preview', {checkoutId});
    },
    terms: () => {
      next('checkout.terms', {checkoutId});
    },
    confirm: () => {
      next('checkout.confirm', {checkoutId});
    },
  };

  const forward = () => {
    if (!checkout) return;

    if (!checkout.steps.length) {
      throw new Error('No steps found in checkout');
    }

    if (currentStep === 'NONE') {
      const firstStep = checkout.steps[0]?.step;
      stepsToNavigation[firstStep]();
      return;
    }

    const currentStepIndex = checkout.steps.findIndex(
      step => step.step === currentStep
    );

    if (currentStepIndex === -1) {
      throw new Error('Invalid checkout step');
    }

    const nextStep = checkout.steps[currentStepIndex + 1]?.step;
    if (!nextStep) {
      throw new Error('No next step found');
    }

    stepsToNavigation[nextStep]();
  };

  const back = () => {
    navigate(-1);
  };

  return {forward, back};
};
