import '../main.css';

import React, {createContext, useContext} from 'react';
import {Toaster} from '../components/Toaster';
import {AnyAPISpec, APIClient} from 'payble-api-client';

export const UIContext = createContext<
  {api?: APIClient<AnyAPISpec>} | undefined
>(undefined);

export const useUIContext = () => useContext(UIContext);

export const PaybleUI: React.FC<{
  children: React.ReactNode;
  api?: APIClient<AnyAPISpec>;
}> = ({api, children}) => {
  return (
    <UIContext.Provider value={{api}}>
      <Toaster />
      {children}
    </UIContext.Provider>
  );
};
