import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {APIOutput} from 'payble-api-client';
import {useSetupSearchParams} from '../../features/setup/hooks/useSetupSearchParams';
import {getAccount} from './getAccount';

type GetAccountFromSearchParamsOptions = {anonymous?: boolean};
export function useGetAccountFromSearchParams(
  options?: GetAccountFromSearchParamsOptions
) {
  const {billerSlug} = useBillerConfig();
  const {
    accountType,
    accountExternalId: externalId,
    verificationCode,
    patch: patchSearchParams,
  } = useSetupSearchParams();

  const {data, error, loading, refetch, requiresVerification} = getAccount({
    accountType,
    externalId,
    verificationCode,
    billerSlug,
    anonymous: options?.anonymous ?? false,
  });

  return {
    loading,
    data,
    error,
    requiresVerification,
    patchSearchParams,
    refetch,
  };
}

export type GetAccountByExternalIdResponse = NonNullable<
  APIOutput<'consumer', 'getAccountByExternalId'>
>;
