import {FC, ReactNode} from 'react';
import {InputAddonProps} from './InputAddon';
import {Controller} from 'react-hook-form';
import {InputPhone} from './InputPhone';
import {useRegisterField} from '../hooks/useRegisterField';
import {FormField} from './FormField';

export interface FormInputPhoneProps extends InputAddonProps {
  label?: ReactNode;
  name: string;
}

export const FormInputPhone: FC<FormInputPhoneProps> = ({
  label,
  name,
  ...props
}) => {
  const {id, form} = useRegisterField(name);

  return (
    <FormField id={id} name={name} label={label}>
      <Controller
        control={form.control}
        name={name}
        render={({formState, field: {onChange, value, disabled}}) => (
          <InputPhone
            id={id}
            {...props}
            onChange={onChange}
            value={value}
            disabled={disabled || formState.isSubmitting}
          />
        )}
      />
    </FormField>
  );
};
