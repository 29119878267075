import {Login} from '../../features/login/Login';
import {useAuthState} from './useAuthState';

export const RequireAuth: React.FC<{
  children: React.ReactNode;
  when?: boolean;
}> = ({children, when = true}) => {
  const isAuthenticated = useAuthState(s => s.state === 'authenticated');

  if (!isAuthenticated && when) {
    return <Login />;
  }

  return <>{children}</>;
};
