import {z} from 'zod';
import {defineFlow, defineStep} from '../FlowDefinition';
import {useFlowNavigate} from '../flows';
import {OptionsStep} from './steps/setup/OptionsStep';
import {AccountProvider} from './providers/AccountProvider';
import {AccountLookupStep} from './steps/shared/AccountLookupStep';
import {handleOnNextSetupOptions} from './helpers/handleOnNextSetupOptions';
import {useSetupNavigate} from 'features/setup/hooks/useSetupNavigate';
import {BaseSetupPage} from '../../components/layout';

export const setup = defineFlow({
  path: '/setup',
  available: () => true,
  steps: {
    lookup: defineStep(
      {
        path: '',
        schema: () =>
          z.object({
            accountExternalId: z.string().optional(),
            verificationCode: z.string().optional(),
            accountType: z.string().optional(),
          }),
        requiresAuth: ({appConfig}) => appConfig.accountLookupRequiresAuth,
      },
      ({requiresAuth}) => {
        const next = useFlowNavigate();

        return (
          <BaseSetupPage>
            <AccountLookupStep
              onNext={({accountExternalId, accountType, verificationCode}) => {
                next('setup.options', {
                  accountExternalId,
                  accountType,
                  verificationCode,
                });
              }}
              authenticated={requiresAuth}
            />
          </BaseSetupPage>
        );
      }
    ),
    options: defineStep(
      {
        schema: () =>
          z.object({
            accountType: z.string(),
            accountExternalId: z.string(),
            verificationCode: z.string().optional(),
          }),
        requiresAuth: () => true,
      },
      ({state: {accountType, verificationCode, accountExternalId}, config}) => {
        const {billerConfig, appConfig} = config;

        const navigate = useSetupNavigate();

        return (
          <BaseSetupPage>
            <AccountProvider
              externalId={accountExternalId}
              accountType={accountType}
              verificationCode={verificationCode}
              anonymous={false}
              billerSlug={billerConfig.billerSlug}
            >
              <OptionsStep
                accountType={accountType}
                accountExternalId={accountExternalId}
                verificationCode={verificationCode}
                shouldLinkAccount={!appConfig.accountLookupRequiresAuth}
                onNext={mode => {
                  handleOnNextSetupOptions({mode, billerConfig, navigate});
                }}
              />
            </AccountProvider>
          </BaseSetupPage>
        );
      }
    ),
  },
});
