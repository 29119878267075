/**
 * This error class is meant to be extended internally to create errors that
 * are reportable internally but are not exposed to clients.
 */
export abstract class InternalError extends Error {
  /**
   * This must be defined for error codes to not be minified-away
   */
  abstract get className(): string;

  previousError?: Error;

  constructor(message: string) {
    super(message);
    this.message = message;
  }

  message: string;

  context: Record<string, any> = {};

  get name(): string {
    return this.previousError
      ? `${this.className}(${this.previousError.name})`
      : this.className;
  }

  getCause(): Error | undefined {
    return this.previousError;
  }

  getRootCause(): Error | undefined {
    const previousErrors = this.getPreviousErrors();
    return previousErrors[previousErrors.length - 1];
  }

  getPreviousErrors(): Error[] {
    const {previousError} = this;
    if (!previousError) return [];
    if (previousError instanceof InternalError) {
      return [previousError, ...previousError.getPreviousErrors()];
    }
    return [previousError];
  }

  toString(): string {
    const currentErrorString = `${this.className}: ${this.message}`;
    return this.previousError
      ? `${currentErrorString}\n\t${this.previousError.toString()}`
      : currentErrorString;
  }

  static wrap<T extends typeof InternalError>(
    this: T,
    error: unknown,
    message?: string,
    context: InstanceType<T>['context'] = {}
  ): InstanceType<T> {
    let previousError: Error;
    if (error instanceof Error) {
      previousError = error;
    } else {
      previousError = new Error(`Unexpected error value thrown: "${error}"`);
    }

    let finalMessage: string;
    if (message) {
      finalMessage = message;
    } else if (previousError instanceof InternalError) {
      finalMessage = previousError.message;
    } else {
      finalMessage = 'Something went wrong';
    }

    const appError = new (this as any)(finalMessage);
    appError.previousError = previousError;
    appError.context = context;
    if (previousError instanceof Error) {
      this.mergeTrace(appError, previousError);
    }

    return appError as InstanceType<T>;
  }

  static create<T extends typeof InternalError>(
    this: T,
    message: string,
    context: InstanceType<T>['context'] = {}
  ): InstanceType<T> {
    const error = new (this as any)(message);
    error.context = context;
    error.previousError = undefined;
    return error as InstanceType<T>;
  }

  private static mergeTrace(target: Error, old?: Error): void {
    if (old && target.stack && old.stack) {
      const messageLines = (target.message.match(/\n/g) || []).length + 1;
      target.stack = `${target.stack
        .split('\n')
        .slice(0, messageLines + 1)
        .join('\n')}\n${old.stack}`;
    }
  }
}

/**
 * Domain errors are meant to be communicated to clients and are meant to be
 * handled by the client. They should be defined in ./StandardErrors.ts to be correctly
 * hydrated by clients.
 */
export abstract class DomainError extends InternalError {
  get httpCode() {
    return 500;
  }

  abstract get code(): string;

  toHTTP() {
    return {
      statusCode: this.httpCode,
      body: {
        code: this.code,
        message: this.message,
        context: this.dehydrateContext(),
      },
    };
  }

  hydrateContext(context: Record<string, unknown>): void {
    this.context = {...this.context, ...context};
  }

  /**
   * This is intentionally empty by default. It is meant to be overridden by
   * sub classes that require additional context data to be set on the error
   * sent to clients that can be rehydrated using `hydrateContext`.
   */
  dehydrateContext(): Record<string, unknown> {
    return {};
  }
}
