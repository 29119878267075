import React, {Fragment} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {
  ChevronDownIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/20/solid';
import {auth} from 'lib/authv2/auth';
import {classNames} from 'lib/styles';
import {navigate} from 'lib/navigation/routes';
import {
  CreditCardIcon,
  PencilIcon,
  BellIcon,
} from '@heroicons/react/24/outline';

type HomeMenuProps = {
  slug: string;
  paymentMethodsOnClick?: () => void;
};

export const HomeMenu: React.FC<HomeMenuProps> = ({
  slug,
  paymentMethodsOnClick,
}) => {
  const {logout} = auth.useAuthMutations();

  const profile = () => {
    navigate('/biller/:slug/profile', {
      slug,
    });
  };

  const linkAccount = () => {
    navigate('/biller/:slug/setup', {
      slug,
    });
  };

  const manageNotifications = () => {
    navigate('/biller/:slug/notification', {
      slug,
    });
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="h-[44px] transition inline-flex justify-center w-full rounded-md border shadow px-5 py-2 bg-white text-sm font-medium text-gray-500 hover:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
          <span className="mt-1">Options</span>
          <ChevronDownIcon
            className="mt-1 -mr-1 ml-2 h-5 w-5"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({active}) => (
                <div
                  onClick={profile}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  <PencilIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Profile
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({active}) => (
                <div
                  onClick={paymentMethodsOnClick ?? profile}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  <CreditCardIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Payment methods
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({active}) => (
                <div
                  onClick={linkAccount}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  <HomeIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Link new property
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({active}) => (
                <div
                  onClick={manageNotifications}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  <BellIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Manage notifications
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({active}) => (
                <div
                  onClick={logout}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  <ArrowRightOnRectangleIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Sign Out
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
