import {z} from 'zod';
import {zISODate} from './generic';

const BaseAnalyticsEvent = z.object({
  path: z.string(),
  meta: z.record(z.string(), z.any()),
  userAgent: z.string(),
  timestamp: z.string().datetime(),
  urlParams: z.record(z.string(), z.any()),
  session: z.string().uuid(),
  referrer: z.string().optional(),
});

export const BillerAnalyticsResponse = z.object({
  total: z.number().int(),
  events: z.array(
    z.object({
      analyticEventId: z.string().uuid(),
      ip: z.string(),
      isMobile: z.boolean(),
      utm: z.string().optional(),
      type: z.string(),
      path: z.string(),
      meta: z.record(z.string(), z.any()),
      timestamp: zISODate,
      deviceId: z.string(),
      sessionId: z.string(),
      referrer: z.string().optional(),
      language: z.string(),
      zoneOffset: z.number(),
    })
  ),
});

export type BillerAnalyticsEvent = z.infer<
  typeof BillerAnalyticsResponse
>['events'][number];

// Make this type more explicit and better, save fields into specific rows in the database
export const AnalyticsEvent = z
  .object({
    type: z.enum([
      'session_start',
      'setup_complete',
      'payment_complete',
      'logged_out',
      'setup_progress',
      'auth_sms_sent',
      'auth_sms_failed',
      'auth_email_sent',
      'auth_start_failed',
      'auth_code_failed',
      'email_verification_completed',
      'email_verification_sent',
      'profile_payment_method_added',
      'account_linked',
    ]),
  })
  .and(BaseAnalyticsEvent);

export const AnalyticsEventRequest = z.object({
  analyticsToken: z.string(),
  events: z.array(AnalyticsEvent),
});

export const AnalyticsIdentifyRequest = z.object({
  billerSlug: z.string(),
  analyticsToken: z.string().optional(),
  hostname: z.string(),
  language: z.string(),
  zoneOffset: z.number(),
});

export const AnalyticsIdentifyResponse = z.object({
  analyticsToken: z.string().or(z.null()),
});
