import {z} from 'zod';
import {zPhoneNumber} from './generic';

export const AuthMethodTypeSchema = z.enum(['mobile', 'email']);

export const AuthMethodSchema = z
  .object({
    loginIdentity: z.string(),
    authMethod: AuthMethodTypeSchema,
    billerSlug: z.string().min(1).trim(),
  })
  .superRefine((data, ctx) => {
    if (data.authMethod === 'mobile') {
      const result = zPhoneNumber.safeParse(data.loginIdentity);
      if (!result.success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: result.error.message,
          path: ['loginIdentity'],
        });
      }
    } else if (data.authMethod === 'email') {
      const result = z.string().email().safeParse(data.loginIdentity);

      if (!result.success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: result.error.message,
          path: ['loginIdentity'],
        });
      }
    }
  });
