import {z} from 'zod';
import {
  ConsumerCheckoutSchema,
  StartCheckoutRequestSchema,
} from '../schemas/checkout';
import {CreatePaymentMethodRequest} from '../schemas/payment';
import {defineHTTPEndpoint} from '../utils';

export const startCheckout = defineHTTPEndpoint({
  authn: 'CONSUMER_OR_PUBLIC',
  operation: 'POST /checkout',
  allowedEnvironments: ['develop'],
  requestSchema: StartCheckoutRequestSchema,
  responseSchema: z.object({
    ct: z.string(),
    checkout: ConsumerCheckoutSchema,
  }),
});

export const getCheckout = defineHTTPEndpoint({
  authn: 'CONSUMER_OR_PUBLIC',
  operation: 'GET /checkout',
  allowedEnvironments: ['develop'],
  requestSchema: z.object({ct: z.string()}),
  responseSchema: ConsumerCheckoutSchema,
  queryKey: data => ['checkout', data?.ct],
});

export const addCheckoutPaymentMethod = defineHTTPEndpoint({
  authn: 'CONSUMER_OR_PUBLIC',
  operation: 'POST /checkout/paymentMethod',
  allowedEnvironments: ['develop'],
  requestSchema: z.object({ct: z.string()}).and(
    z.discriminatedUnion('type', [
      z.object({
        type: z.literal('attach'),
        paymentMethodId: z.string().uuid(),
      }),
      z.object({
        type: z.literal('create'),
        paymentMethod: CreatePaymentMethodRequest,
      }),
    ])
  ),
  responseSchema: ConsumerCheckoutSchema,
});

export const completeCheckout = defineHTTPEndpoint({
  authn: 'CONSUMER_OR_PUBLIC',
  operation: 'POST /checkout/complete',
  requestSchema: z.object({ct: z.string()}),
  responseSchema: ConsumerCheckoutSchema,
  allowedEnvironments: ['develop'],
});
