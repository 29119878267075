import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useFlowNavigate} from '../../flows/flows';
import {useConsumerAppConfig} from '../appConfig/ConsumerAppConfig';

const parseRedirect = (params: Record<string, string | undefined>) => {
  let accountType = (params.t ?? params[''])?.toLowerCase();

  if (params.v && params.v === '1' && accountType && params.e) {
    if (accountType?.trim() === 'instalment') {
      /**
       * Cessnock City Council shipped a QR code that used "instalment" as the
       * account type instead of rates in late 2023. This patch can likely be
       * removed after mid 2024
       */
      accountType = 'rates';
    }

    const accountExternalId = params.e;
    const verificationCode = params.a;
    const source = params.s;

    return {
      type: 'v1' as const,
      accountType,
      accountExternalId,
      verificationCode,
      source,
    };
  }

  return null;
};

export const RedirectManager: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const flowNavigate = useFlowNavigate();
  const location = useLocation();
  const navigate = useNavigate();
  const appConfig = useConsumerAppConfig();

  useEffect(() => {
    const redirect = parseRedirect(
      Object.fromEntries(new URLSearchParams(location.search))
    );
    if (redirect?.type === 'v1') {
      flowNavigate('setup.lookup', {
        accountExternalId: redirect.accountExternalId,
        accountType: redirect.accountType,
        verificationCode: redirect.verificationCode,
      });
      return;
    }

    if (!location.pathname.startsWith(`/biller/${appConfig.biller.slug}`)) {
      const search = new URLSearchParams(location.search);
      const searchString = search.size ? `?${search.toString()}` : '';
      navigate(`/biller/${appConfig.biller.slug}${searchString}`);
      return;
    }
  }, [
    location.search,
    location.pathname,
    navigate,
    flowNavigate,
    appConfig.biller.slug,
  ]);

  return <>{children}</>;
};
