import {
  APIClient,
  ConsumerAPIClient,
  consumerAPISpec,
  consumerQueryConfig,
} from 'payble-api-client';
import React, {ReactNode, useContext} from 'react';
import {makeAPIHooks} from 'payble-api-react-query';
import {makeErrorInvalidator, makeInvalidator} from 'payble-api-react-query';
import {queryClient} from '../queryClient';

interface APIContext {
  api: ConsumerAPIClient;
}

export const api = new APIClient({
  definitions: consumerAPISpec,
  baseURL: '/api/v2',
});

// @ts-expect-error This is used by e2e tests to manually trigger requests
window.api = api;

export const Context = React.createContext<APIContext>({
  api,
});

export const Provider: React.FC<{children: ReactNode}> = ({children}) => {
  return <Context.Provider value={{api}}> {children} </Context.Provider>;
};

export const useAPI = () => {
  return useContext(Context);
};

api.successTopic.subscribe(makeInvalidator(queryClient, consumerQueryConfig));

api.errorTopic.subscribe(
  makeErrorInvalidator(queryClient, consumerQueryConfig)
);

const {useAPIInvalidate, useAPIMutation, useAPIQuery} = makeAPIHooks(
  consumerAPISpec,
  consumerQueryConfig,
  useAPI
);

export {useAPIInvalidate, useAPIMutation, useAPIQuery};
