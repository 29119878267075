import {z} from 'zod';
import {zAbsoluteDate, zNumberString} from './generic';

export const AccountInstalmentRangeResponse = z.object({
  minimumRequiredPayments: z
    .object({
      weekly: z.number().int(),
      fortnightly: z.number().int(),
      monthly: z.number().int(),
      quarterly: z.number().int(),
      end_of_month: z.number().int(),
      annually: z.number().int(),
    })
    .or(z.null()),
  flexibleInstalmets: z
    .object({
      weekly: z.number().int(),
      fortnightly: z.number().int(),
      monthly: z.number().int(),
      quarterly: z.number().int(),
    })
    .or(z.null()),
});

export const ACCOUNT_STANDING_LABELS = {
  ON_TRACK: 'On track',
  OVERDUE: 'Overdue',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  NONE: 'None',
};

export const accountStanding = z.enum(
  Object.keys(ACCOUNT_STANDING_LABELS) as [
    (keyof typeof ACCOUNT_STANDING_LABELS)[number],
    ...string[],
  ]
);

export const accountResponse = z.object({
  id: z.string(),
  type: z.string(),
  externalId: z.string(),
  description: z.string(),
  amountOwing: z.number(),
  status: z.string(),
  meta: z.object({
    nextDueDate: z.string().optional(),
    averageWeeklyPayment: z.number().optional(),
  }),
  collectionExemption: z.string().optional(),
  setupBreakdown: z.union([
    z.array(
      z.object({
        amount: z.number(),
        label: z.string(),
      })
    ),
    z.null(),
  ]),
  targetInstalments: z.array(
    z.object({
      amount: z.number(),
      dueAt: zAbsoluteDate,
    })
  ),
  targetDate: zAbsoluteDate.nullable(),
  paymentOptions: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
        description: z.string(),
      })
    )
    .optional(),
  catchUp: z
    .object({
      amount: z.number(),
      targetDate: zAbsoluteDate,
    })
    .nullable(),
  hasActivePlan: z.boolean().optional(),
  hasLegacyPlan: z.boolean().optional(),
  warnings: z
    .array(
      z.object({
        type: z.string(),
        ownType: z.enum(['owned', 'ownedByOther']),
        message: z.string(),
      })
    )
    .optional(),
  issueDate: zAbsoluteDate.nullable(),
  dueDate: zAbsoluteDate.nullable(),
});

export type AccountResponse = NonNullable<z.infer<typeof accountResponse>>;

export const accountStandingOwnerType = z.enum(['PAYER', 'CONTACT']);

export const accountStandingSchema = z.object({
  status: accountStanding,
  label: z.string(),
  tooltip: z.string().optional(),
  canUnlink: z.boolean(),
  owner: z
    .object({
      type: accountStandingOwnerType,
      id: z.string(),
    })
    .optional(),
});

export const filterAccountsSchema = z.object({
  externalId: z.string().optional(),
  types: z.array(z.string()).optional(),
  description: z.string().optional(),
  mobile: z.string().optional(),
  mobileVerified: z.boolean().optional(),
  email: z.string().email().optional(),
  emailVerified: z.boolean().optional(),
  amountOwing: z
    .object({
      from: z.optional(zNumberString),
      to: z.optional(zNumberString),
    })
    .optional(),
  standing: z
    .object({
      statuses: z.array(accountStanding).optional(),
    })
    .optional(),
});

export type FilterAccountsSchema = NonNullable<
  z.infer<typeof filterAccountsSchema>
>;

export const accountSearchResponse = z.object({
  accountId: z.string(),
  type: z.string(),
  externalId: z.string(),
  description: z.string(),
  amountOwing: z.number(),
  status: z.string(),
  standing: accountStandingSchema.optional(),
});

export type AccountSearchResponse = NonNullable<
  z.infer<typeof accountSearchResponse>
>;

export const billerAccountResponse = z.object({
  id: z.string(),
  type: z.string(),
  externalId: z.string(),
  description: z.string(),
  amountOwing: z.number(),
  status: z.string(),
  meta: z.object({
    nextDueDate: z.string().optional(),
    averageWeeklyPayment: z.number().optional(),
  }),
  setupBreakdown: z.union([
    z.array(
      z.object({
        amount: z.number(),
        label: z.string(),
      })
    ),
    z.null(),
  ]),
  targetInstalments: z.array(
    z.object({
      amount: z.number(),
      dueAt: zAbsoluteDate,
    })
  ),
});
