import {errs} from 'payble-shared';
import {useToast} from 'payble-ui';
import {useEffect, useMemo} from 'react';
import _refiner from 'refiner-js';
import {analytics} from '../../analytics/hooks/useAnalytics';
import {queryClient} from '../../queryClient';
import {api, useAPIMutation, useAPIQuery} from '../api';
import {getBillerSlugFromUrl} from '../url';
import {AuthInfoStore} from './AuthInfoStore';
import {SessionStore} from './SessionStore';
import {SessionTimeout} from './SessionTimeout';
import {resetAuthState, useAuthState} from './useAuthState';

export const auth = {
  useCurrentUser: () => {
    const user = useAuthState(s =>
      s.state === 'authenticated' ? s.user : null
    );
    if (!user) {
      throw new Error('No user found');
    }
    return user;
  },

  useMaybeUser: () => {
    return useAuthState(s => (s.state === 'authenticated' ? s.user : null));
  },

  useAuthMutations: () => {
    const startLogin = useAPIMutation('loginStart', {
      query: {
        onSuccess: ({authMethod}) => {
          if (authMethod === 'mobile') {
            analytics.addEvent('auth_sms_sent');
          }
          if (authMethod === 'email') {
            analytics.addEvent('auth_email_sent');
          }
        },
        onError: () => {
          analytics.addEvent('auth_start_failed');
        },
      },
    });

    const completeLogin = useAPIMutation('loginComplete', {
      query: {
        onSuccess: ({id_token}, {authMethod}) => {
          auth.receivedFreshToken(id_token);
          AuthInfoStore.write({
            lastSessionStarted: new Date(),
            lastAuthMethod: authMethod,
          });
        },
        onError: () => {
          analytics.addEvent('auth_code_failed');
        },
      },
    });

    return {
      startLogin,
      completeLogin,
      logout: auth.userInitiatedLogout,
    };
  },

  useContact: () => {
    const contact = auth.useMaybeContact();
    if (!contact) {
      throw new Error('No contact found');
    }
    return contact;
  },

  useMaybeContact: () => {
    return useAuthState(s =>
      s.state === 'authenticated' ? s.user.contact : null
    );
  },

  usePlan: (planId: string) => {
    return useAuthState(s =>
      s.state === 'authenticated'
        ? s.user.plans.find(p => p.id === planId)
        : null
    );
  },

  useFetchingUser: () => {
    return useAuthState(s => s.state === 'authenticated' && s.fetchingUser);
  },

  usePlans: () => {
    return useAuthState(s => (s.state === 'authenticated' ? s.user.plans : []));
  },

  usePayment: (paymentId: string) => {
    return useAuthState(s =>
      s.state === 'authenticated'
        ? s.user.payments.find(p => p.id === paymentId)
        : null
    );
  },

  usePayments: () => {
    return useAuthState(s =>
      s.state === 'authenticated' ? s.user.payments : []
    );
  },

  usePaymentMethod(paymentMethodId: string) {
    return useAuthState(s =>
      s.state === 'authenticated'
        ? s.user.paymentMethods.find(pm => pm.id === paymentMethodId)
        : null
    );
  },

  usePaymentMethods: () => {
    return useAuthState(s =>
      s.state === 'authenticated' ? s.user.paymentMethods : []
    );
  },

  userInitiatedLogout: () => {
    api.request('logout', {}).tap(() => {}, console.error);
    analytics.addEvent('logged_out');
    auth.wipe();
  },

  receivedFreshToken: (token: string) => {
    SessionStore.write({id_token: token});
    queryClient.clear();
    resetAuthState();
  },

  useIsLoggedIn: () => {
    return useAuthState(s => s.state === 'authenticated');
  },

  useAuthRequests: () => {
    const state = useAuthState(s => s.state);
    const sessionExists = useAuthState(s => !!s.session);

    const contact = useAPIQuery('getSessionContact', {
      query: {
        enabled: sessionExists,
        retry: (tries, error) =>
          !(error instanceof errs.AuthenticationError) && tries < 3,
      },
    });

    const plans = useAPIQuery('getInstalmentPlans', {
      query: {
        enabled: sessionExists,
        retry: (tries, error) =>
          !(error instanceof errs.AuthenticationError) && tries < 3,
      },
    });

    const payments = useAPIQuery('getPayments', {
      query: {
        enabled: sessionExists,
        retry: (tries, error) =>
          !(error instanceof errs.AuthenticationError) && tries < 3,
      },
    });

    const isFetching =
      contact.isFetching || plans.isFetching || payments.isFetching;

    const error = useMemo(() => {
      return contact.error || plans.error || payments.error;
    }, [contact.error, plans.error, payments.error]);

    const data = useMemo(() => {
      return contact.data && plans.data && payments.data
        ? {
            contact: contact.data,
            plans: plans.data,
            payments: payments.data,
          }
        : null;
    }, [contact.data, plans.data, payments.data]);

    useEffect(() => {
      if (data && sessionExists) {
        if (state === 'initializing' || state === 'authenticated') {
          useAuthState.setState({
            state: 'authenticated',
            fetchingUser: isFetching,
            user: {
              contact: data.contact,
              contactId: data.contact.contactId,
              paymentMethods: data.contact.paymentMethods,
              payments: data.payments,
              plans: data.plans,
            },
          });
        }
      }

      if (error && state === 'initializing') {
        useAuthState.setState({
          state: 'failed_to_load',
          error: error,
        });
      }
    }, [data, state, sessionExists, error, isFetching]);
  },

  useMountedAuthWarnings: () => {
    const toaster = useToast();

    useEffect(
      () =>
        SessionTimeout.topic.subscribe(event => {
          if (event.type === 'session_expiring_soon') {
            toaster.toast({
              title: 'Session Expiring Soon',
              description: 'Your session will expire in 10 minutes',
            });
          }

          if (event.type === 'session_expiring_now') {
            toaster.toast({
              title: 'Session Expired',
              description: 'Your session has expired',
            });
            auth.wipe();
          }
        }),
      [toaster.toast]
    );
  },

  wipe: () => {
    setTimeout(() => {
      SessionStore.write(null);
      resetAuthState();
      queryClient.clear();
    }, 0);
  },

  onBoot: () => {
    setTimeout(() => {
      useAuthState.setState({
        state: 'initializing',
        session: SessionStore.read(),
      });
    }, 0);
  },
};

auth.onBoot();

useAuthState.subscribe((state, prev) => {
  if (state.state === 'authenticated') {
    // @ts-expect-error This is used by e2e tests
    window.contactId = state.user.contactId;
  }

  if (state.state === 'authenticated' && prev.state !== 'authenticated') {
    analytics.reidentify();

    _refiner('identifyUser', {
      id: state.user.contactId,
      biller_slug: getBillerSlugFromUrl(),
    });
  }

  if (state.session !== prev.session) {
    SessionTimeout.write(state.session);
    if (state.session) {
      api.setAuth({
        type: 'consumer',
        token: state.session.token,
      });
    } else {
      api.setAuth({type: 'anonymous'});
    }
  }

  if (state.state === 'uninitialized') {
    const stored = SessionStore.read();

    useAuthState.setState({
      state: 'initializing',
      session: stored
        ? {token: stored.token, expiresAt: stored.expiresAt}
        : null,
    });
  }

  if (state.state === 'initializing' && state.session == null) {
    useAuthState.setState({
      state: 'unauthenticated',
      session: null,
    });
  }
});

api.errorTopic.subscribe(event => {
  if (
    event.error instanceof errs.AuthenticationError &&
    event.error.context.authn !== 'PUBLIC'
  ) {
    auth.wipe();
  }
});
