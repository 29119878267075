import {z} from 'zod';

const AuthInfoSchema = z.object({
  lastSessionStarted: z
    .string()
    .transform(v => new Date(v))
    .pipe(z.date())
    .or(z.date())
    .optional(),
  lastAuthMethod: z.enum(['email', 'mobile']).or(z.undefined()),
});

type AuthInfo = z.infer<typeof AuthInfoSchema>;

const AUTH_INFO_KEY = 'auth_info';

const LEGACY_SESSION_INFO_PATH = 'signed-up';

export const AuthInfoStore = {
  write: (info: AuthInfo) => {
    try {
      localStorage.setItem(
        AUTH_INFO_KEY,
        JSON.stringify(AuthInfoSchema.parse(info))
      );
    } catch (e) {
      console.error(e);
    }
  },
  read: (): AuthInfo => {
    try {
      const value = localStorage.getItem(AUTH_INFO_KEY);
      if (value) {
        return AuthInfoSchema.parse(JSON.parse(value));
      }

      const hasUsedLegacyAuth = localStorage.getItem(LEGACY_SESSION_INFO_PATH);

      return {
        lastAuthMethod: hasUsedLegacyAuth ? 'mobile' : undefined,
        lastSessionStarted: hasUsedLegacyAuth ? new Date(0) : undefined,
      };
    } catch (e) {
      console.error(e);
      return {};
    }
  },
};
