import React from 'react';

import {Debbie} from 'components/organisms/Debbie';

import {Formik, Form, Field} from 'formik';

import {PaymentOption} from 'payble-shared';
import {goBack} from 'lib/navigation/routes';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {SetupOptionsRadio} from 'features/setup/components/SetupOptionsRadio';
import {useAccountProvider} from '../../providers/AccountProvider';
import {useAPIMutation} from 'lib/api';
import {Loading} from 'payble-ui';

type OptionsStepProps = {
  onNext: (mode: PaymentOption['value']) => void;
  accountType: string;
  accountExternalId: string;
  shouldLinkAccount?: boolean;
  verificationCode?: string;
};
export const OptionsStep: React.FC<OptionsStepProps> = ({
  onNext,
  accountExternalId,
  verificationCode,
  accountType,
  shouldLinkAccount,
}) => {
  const billerConfig = useBillerConfig();

  const {mutateAsync: linkAccount, isPending: loading} = useAPIMutation(
    'addContactToAccount',
    {
      query: {
        onError(e) {
          throw e;
        },
      },
    }
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Formik<{mode?: PaymentOption['value']}>
      initialValues={{}}
      onSubmit={async ({mode}) => {
        if (!mode) {
          return;
        }

        if (shouldLinkAccount) {
          await linkAccount({
            accountExternalId: accountExternalId,
            accountType,
            verificationCode,
          });
        }

        onNext(mode);
      }}
    >
      <Form className="relative">
        <Debbie
          title={`How would you like to pay your ${billerConfig.getAccountTypeTitle(
            accountType
          )}?`}
        />
        <div className="flex flex-col items-center justify-center flex-1 h-full">
          <SetupOptionsRadio getAccount={() => useAccountProvider()} />
          <Field>
            {({form}: any) => (
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={!form.values.mode}
              >
                Next
              </button>
            )}
          </Field>
          <button
            type="button"
            onClick={() => {
              goBack();
            }}
            className="mt-6 text-blue-600 transition hover:text-blue-700"
          >
            Back
          </button>
        </div>
      </Form>
    </Formik>
  );
};
