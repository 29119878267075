import React, {ReactNode, useMemo} from 'react';
import {RawBillerConfig, BillerConfig, BillerSlug} from 'payble-shared';
import {ErrorPage} from '../../features/navigation/Error';
import {useConsumerAppConfig} from './ConsumerAppConfig';

export const Context = React.createContext<BillerConfig | undefined>(undefined);

export const Provider: React.FC<{children: ReactNode}> = ({children}) => {
  const appConfig = useConsumerAppConfig();

  /**
   * This should be replaced by an async query, for now biller config is hardcoded in packages/shared
   */
  const config = useMemo(
    () => RawBillerConfig[appConfig.biller.slug as BillerSlug],
    [appConfig.biller.slug]
  );

  if (!config) {
    return <ErrorPage />;
  }

  return (
    <Context.Provider
      value={new BillerConfig(config, appConfig.biller.slug as BillerSlug)}
    >
      {children}
    </Context.Provider>
  );
};
