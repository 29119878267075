import {GetAccountByExternalIdResponse} from 'lib/account/useGetAccountFromSearchParams';
import {useAPIMutation} from 'lib/api';
import {useCallback, useState} from 'react';
import {analytics} from '../../../analytics/hooks/useAnalytics';
import {useSetupSearchParams} from './useSetupSearchParams';

const getStateFromMode = (mode: string) => {
  switch (mode) {
    case 'PAY_AMOUNT':
      return 'CREATE_PAY_AMOUNT';
    case 'PAY_BALANCED':
      return 'CREATE_PAY_BALANCED';
    case 'PAY_AUTO':
      return 'CREATE_PAY_X_ON_Y';
    case 'PAY_IN_FULL':
      return 'CREATE_PAY_IN_FULL';
    case 'PAY_X_EVERY_Z':
      return 'CREATE_PAY_X_EVERY_Z';
    case 'PAY_ON_Y':
      return 'CREATE_SCHEDULED_PAYMENT';
    case 'PAY_EVERY_X':
      return 'CREATE_PAY_EVERY_X';
    case 'TOKENISE':
      return 'TOKENISE';
    case 'PAY_SMOOTH':
      return 'CREATE_PAY_SMOOTH';
    case 'PAY_CATCH_UP':
      return 'CREATE_PAY_CATCH_UP';
    case 'REQUEST_PLAN':
      return 'CREATE_PLAN_REQUEST';
    default:
      throw new Error(`Invalid pay mode: ${mode}`);
  }
};

export function useSubmitSetupMutation({mode}: {mode: string}) {
  const [error, setError] = useState('');
  const {
    instalmentStartAt,
    paymentTargetDate,
    paymentStartDate,
    instalmentFrequency,
    instalmentTargetDate,
  } = useSetupSearchParams();

  const {
    mutateAsync: createInstalmentPlan,
    data: instalmentPlan,
    isPending: isCreatingInstalmentPlan,
    reset: resetInstalmentPlan,
  } = useAPIMutation('createInstalmentPlan');

  const {
    mutateAsync: payAccountAmount,
    data: payment,
    isPending: isPayingAccountAmount,
    reset: resetPayAccountAmount,
  } = useAPIMutation('payAccountAmount');

  const {
    mutateAsync: createPlanRequest,
    data: planRequest,
    isPending: isRequestingPlan,
    reset: resetPlanRequest,
  } = useAPIMutation('createPlanRequest');

  const loading =
    isCreatingInstalmentPlan || isPayingAccountAmount || isRequestingPlan;

  const pay = useCallback(
    async (
      amountInCents: number,
      options: {
        account: GetAccountByExternalIdResponse;
        paymentMethod: {id: string};
        contact: {contactId: string};
      }
    ) => {
      const action = getStateFromMode(mode);
      setError('');

      try {
        resetInstalmentPlan();
        resetPayAccountAmount();
        resetPlanRequest();

        const {account, paymentMethod, contact} = options;

        switch (action) {
          case 'CREATE_PAY_AMOUNT': {
            await payAccountAmount({
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              amountInCents,
            });
            break;
          }
          case 'CREATE_SCHEDULED_PAYMENT': {
            if (!paymentTargetDate) {
              throw new Error(
                'Could not create a scheduled payment without a target date'
              );
            }

            await createInstalmentPlan({
              payMode: 'pay-on-y',
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: 'weekly',
              targetDate: paymentTargetDate,
              amount: amountInCents,
            });
            break;
          }
          case 'CREATE_PAY_X_ON_Y': {
            if (!paymentMethod?.id) {
              throw new Error(
                `Could not create an instalment plan without a contact payment method id for accountId: ${account.id}`
              );
            }

            if (!instalmentFrequency) {
              throw new Error(
                'Could not create an instalment plan without an instalment frequency'
              );
            }

            await createInstalmentPlan({
              payMode: 'pay-x-on-y',
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              amount: account.amountOwing,
            });
            break;
          }
          case 'CREATE_PAY_BALANCED': {
            if (!paymentMethod.id) {
              throw new Error(
                'Could not create an instalment plan without a contact payment method id'
              );
            }

            if (!instalmentStartAt) {
              throw new Error(
                'Could not create an instalment plan without a start date'
              );
            }

            if (!instalmentFrequency) {
              throw new Error(
                'Could not create an instalment plan without an instalment frequency'
              );
            }

            await createInstalmentPlan({
              payMode: 'balanced-pay-every-x',
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: instalmentStartAt,
            });
            break;
          }
          case 'CREATE_PAY_SMOOTH': {
            if (!paymentMethod.id) {
              throw new Error(
                'Could not create an instalment plan without a contact payment method id'
              );
            }

            if (!instalmentStartAt) {
              throw new Error(
                'Could not create an instalment plan without a start date'
              );
            }

            if (!instalmentFrequency) {
              throw new Error(
                'Could not create an instalment plan without an instalment frequency'
              );
            }

            if (amountInCents < 0) {
              throw new Error(
                `Could not create an instalment plan with ${amountInCents}. Value has to number greater than 0`
              );
            }

            await createInstalmentPlan({
              payMode: 'smooth-pay',
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: instalmentStartAt,
              amount: amountInCents,
              offPeriodInstalmentAmount: amountInCents,
            });
            break;
          }
          case 'CREATE_PAY_EVERY_X': {
            if (!paymentMethod?.id) {
              throw new Error(
                'Could not create an instalment plan without a contact payment method id'
              );
            }

            if (!instalmentStartAt) {
              throw new Error(
                'Could not create an instalment plan without a start date'
              );
            }

            if (!instalmentFrequency) {
              throw new Error(
                'Could not create an instalment plan without an instalment frequency'
              );
            }

            await createInstalmentPlan({
              payMode: 'pay-every-x',
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: instalmentStartAt,
            });
            break;
          }
          case 'CREATE_PAY_X_EVERY_Z': {
            if (!paymentStartDate) {
              throw new Error(
                'Could not create an instalment plan without a start date'
              );
            }

            if (!instalmentFrequency) {
              throw new Error(
                'Could not create an instalment plan without an instalment frequency'
              );
            }

            await createInstalmentPlan({
              payMode: 'pay-x-every-z',
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: paymentStartDate,
              amount: amountInCents,
            });
            break;
          }
          case 'CREATE_PAY_CATCH_UP': {
            if (!instalmentStartAt) {
              throw new Error(
                'Could not create an instalment plan without a start date'
              );
            }

            if (!instalmentTargetDate) {
              throw new Error(
                'Could not create an instalment plan without a target date'
              );
            }

            if (!instalmentFrequency) {
              throw new Error(
                'Could not create an instalment plan without an instalment frequency'
              );
            }

            await createInstalmentPlan({
              payMode: 'catch-up',
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: instalmentStartAt,
              targetDate: instalmentTargetDate,
            });
            break;
          }
          case 'CREATE_PLAN_REQUEST': {
            if (!instalmentStartAt) {
              throw new Error(
                'Could not create a plan request without a start date'
              );
            }

            if (!instalmentFrequency) {
              throw new Error(
                'Could not create an instalment plan without an instalment frequency'
              );
            }

            await createPlanRequest({
              accountId: account.id,
              instalmentAmount: amountInCents,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: instalmentStartAt,
              contactId: contact.contactId,
            });
            break;
          }
          default: {
            throw new Error('Invalid pay mode');
          }
        }

        analytics.addEvent(
          action === 'CREATE_PAY_AMOUNT' ? 'payment_complete' : 'setup_complete'
        );
      } catch (error) {
        setError((error as Error).message);
      }
    },
    []
  );

  return {pay, payment, instalmentPlan, planRequest, loading, error};
}
