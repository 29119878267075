import {z} from 'zod';
import {FormConfig} from '../../types';

export const reporting_dashboards = {
  title: 'Reporting Dashboards',
  configType: 'biller',

  validation: z.object({
    test: z.boolean().optional(),
    dashboards: z.array(
      z.object({
        reportingDashboardId: z.string().uuid(),
        displayName: z.string().trim(),
        metabaseDashboardId: z
          .string()
          .transform(s => Number(s))
          .or(z.number())
          .pipe(z.number().int().positive()),
        paybleStaffOnly: z.boolean(),
      })
    ),
  }),

  form: [
    {
      type: 'list',
      name: 'dashboards',
      label: 'Dashboards',
      fields: [
        {
          type: 'uuid',
          name: 'reportingDashboardId',
          label: 'Reporting Dashboard ID',
          hidden: true,
        },
        {
          type: 'text',
          name: 'displayName',
          label: 'Display Name',
        },
        {
          type: 'number',
          name: 'metabaseDashboardId',
          label: 'Numeric Metabase Dashboard ID',
        },
        {
          type: 'toggle',
          name: 'paybleStaffOnly',
          label: 'Payble Staff Only',
        },
      ],
      addItemLabel: 'Add Dashboard',
    },
  ],
} satisfies FormConfig;
