import {APIOutput} from 'payble-api-client';
import {errs} from 'payble-shared';
import {create} from 'zustand';

interface UninitializedState {
  state: 'uninitialized';
  session: null;
}

interface InitializingState {
  state: 'initializing';
  session: {
    token: string;
    expiresAt: Date;
  } | null;
}

interface InitialLoadError {
  state: 'failed_to_load';
  session: {
    token: string;
    expiresAt: Date;
  } | null;
  error: errs.DomainError;
}

interface UnauthenticatedState {
  state: 'unauthenticated';
  session: null;
}

interface AuthenticatedState {
  state: 'authenticated';

  session: {
    token: string;
    expiresAt: Date;
  };

  fetchingUser: boolean;

  user: {
    contactId: string;
    contact: Omit<APIOutput<'consumer', 'getSessionContact'>, 'paymentMethods'>;
    paymentMethods: APIOutput<
      'consumer',
      'getSessionContact'
    >['paymentMethods'];
    plans: APIOutput<'consumer', 'getInstalmentPlans'>;
    payments: APIOutput<'consumer', 'getPayments'>;
  };
}

type AuthState =
  | UninitializedState
  | InitializingState
  | UnauthenticatedState
  | InitialLoadError
  | AuthenticatedState;

const getInitialState = (): AuthState => ({
  state: 'uninitialized',
  session: null,
});

export const useAuthState = create<AuthState>(getInitialState);

export const resetAuthState = (state?: AuthState) =>
  useAuthState.setState(
    () => ({
      ...getInitialState(),
      ...state,
    }),
    true
  );
