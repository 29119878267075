import {ErrorPage} from '../../features/navigation/Error';
import {Loading} from '../../features/navigation/Loading';
import {auth} from './auth';
import {useAuthState} from './useAuthState';

export const AuthProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  auth.useMountedAuthWarnings();
  auth.useAuthRequests();

  const showLoading = useAuthState(
    s => s.state === 'uninitialized' || s.state === 'initializing'
  );

  const authError = useAuthState(s =>
    s.state === 'failed_to_load' ? s.error : null
  );

  if (showLoading) {
    return <Loading />;
  }

  if (authError) {
    return (
      <ErrorPage
        title="Something went wrong"
        subtitle={authError.message}
        action={{
          onClick: auth.wipe,
          title: 'Try again',
        }}
      />
    );
  }

  return <>{children}</>;
};
