import React from 'react';

import {Button, Form} from 'payble-ui';
import {z} from 'zod';
import {FormErrorMessage} from 'payble-ui/src/components/FormErrorMessage';
import {auth} from '../../../lib/authv2/auth';
import {useConsumerAppConfig} from '../../../lib/appConfig/ConsumerAppConfig';

export const ConfirmEmail: React.FC<{
  email: string;
  goBack: () => void;
  tryAgain: () => void;
}> = ({email, goBack, tryAgain}) => {
  const {completeLogin} = auth.useAuthMutations();
  const appConfig = useConsumerAppConfig();

  return (
    <div className="flex flex-col items-center justify-center gap-4 mx-auto w-72">
      <Form
        schema={z.object({
          otpNumber: z.string().length(6),
        })}
        defaultValues={{
          otpNumber: '',
        }}
      >
        <p className="mt-8 text-lg font-bold text-center w-72 text-navy">
          Email verification
        </p>
        <div className="mt-8 text-center text-gray-600 w-72 ">
          Enter the code you received at{' '}
          <p className="font-bold text-navy">{email}</p>
        </div>

        <Form.Connect>
          {(_, form) => (
            <>
              <Form.InputOTP
                name="otpNumber"
                onComplete={otpNumber =>
                  form.handleSubmit(async () => {
                    try {
                      await completeLogin.mutateAsync({
                        billerSlug: appConfig.biller.slug,
                        authMethod: 'email',
                        loginIdentity: email,
                        verificationCode: otpNumber,
                      });
                    } catch (error) {
                      form.setValue('otpNumber', '');
                    }
                  })()
                }
              />
              <Button
                onClick={goBack}
                variant="link"
                isLoading={form.formState.isLoading}
                disabled={
                  form.formState.isSubmitting || form.formState.isLoading
                }
              >
                Go back
              </Button>
            </>
          )}
        </Form.Connect>
      </Form>

      {completeLogin.error && (
        <>
          <Button onClick={tryAgain}>Try again</Button>
          <FormErrorMessage message={completeLogin.error.message} />
        </>
      )}
    </div>
  );
};
