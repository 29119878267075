import {z} from 'zod';
import {FormConfig} from '../../types';

export const saml = {
  title: 'SAML SSO',
  configType: 'biller',

  validation: z.object({
    providers: z.array(
      z.object({
        identityProviderId: z.string().uuid(),
        metadataURL: z.string().url().trim(),
        entrypoint: z.string().trim(),
        signatureAlgorithm: z.enum(['sha1', 'sha256', 'sha512']),
        // NOTE: debug and validateInResponseTo should not be optional but the
        // form currently does not register on render correctly.
        debug: z.boolean({
          required_error:
            'You have to toggle this on and off due to an issue with the form library',
        }),
        validateInResponseTo: z.boolean({
          required_error:
            'You have to toggle this on and off due to an issue with the form library',
        }),
        alias: z.string().trim(),
        domains: z.array(
          z.object({
            domain: z.string().trim(),
          })
        ),
      })
    ),
  }),

  form: [
    {
      type: 'list',
      label: 'Identity Providers',
      name: 'providers',
      description: 'NOTE: Identity providers cannot currently be deleted.',
      fields: [
        {
          type: 'uuid',
          name: 'identityProviderId',
          label: 'Identity Provider ID',
          hidden: false,
        },
        {
          type: 'text',
          name: 'metadataURL',
          label: 'Metadata URL',
          description:
            'REQUIRED: The metadata URL typically provided by ActiveDirectory.\n Typically looks like: https://login.microsoftonline.com/:someUUID/federationmetadata/2007-06/federationmetadata.xml?appid=:anotherUUID',
        },
        {
          type: 'text',
          name: 'entrypoint',
          label: 'Entrypoint',
          description:
            'Optionally the SAML2 entrypoint. This looks like https://login.microsoftonline.com/:someUUID/saml2',
        },
        {
          type: 'dropdown',
          name: 'signatureAlgorithm',
          label: 'Signature Algorithm',
          options: ['sha1', 'sha256', 'sha512'],
          defaultValue: 'sha256',
          description:
            'Unless specifically requested this should be sha256 which is the AAD default.',
        },
        {
          type: 'toggle',
          name: 'debug',
          label: 'Enable Debug',
          description:
            'If you encounter issues when logging in or when mapping group attributes you can enable this to print the SAML request and emit detailed logs on a failed request. Do not leave this enabled',
        },
        {
          type: 'toggle',
          name: 'validateInResponseTo',
          label: 'Validate In Response To',
          description:
            'Enables checking the InResponseTo attribute in the SAML response to prevent replay attacks. This prevents unsolicited IDP initiated logins.',
        },
        {
          type: 'text',
          name: 'alias',
          label: 'Alias',
          description:
            'Optional GLOBALLY UNIQUE alias that can be used in place of the :identityProviderId in the URL for all requests. e.g. https://billers.payble.com.au/api/saml/:alias. Recommended to use the biller slug like: city-of-exampleton',
        },
        {
          type: 'list',
          name: 'domains',
          label: 'Email Domains',
          description:
            'Used to match logins with emails to the identity provider without needing to manually enter the URL. If someone types "james@payble.com.au" into the login form and this includes "payble.com.au" the user will be redirected to this identity provider',
          fields: [
            {
              type: 'text',
              label: 'Domain',
              name: 'domain',
              placeholder: 'payble.com.au',
            },
          ],
        },
      ],
    },
  ],
} satisfies FormConfig;
