import jwtDecode from 'jwt-decode';
import {DateTime} from 'luxon';
import {z} from 'zod';

const TokenDataSchema = z.object({
  exp: z.number(),
  billerId: z.string(),
  contactId: z.string(),
});

const StoredDataSchema = z.object({
  id_token: z.string(),
});

interface SessionState {
  token: string;
  billerId: string;
  contactId: string;
  expiresAt: Date;
}

/**
 * This and the StoredValue are important to ensure that existing sessions are
 * not invalidated.
 */
const JWT_KEY = 'jwt_auth';
type StoredValue = {
  id_token: string;
};

export const SessionStore = {
  write(value: StoredValue | null) {
    if (value) {
      localStorage.setItem(
        JWT_KEY,
        JSON.stringify(StoredDataSchema.parse(value))
      );
    } else {
      localStorage.removeItem(JWT_KEY);
    }
  },

  read(): SessionState | null {
    const value = localStorage.getItem(JWT_KEY);
    if (!value) return null;

    try {
      const {id_token} = StoredDataSchema.parse(JSON.parse(value));

      const {billerId, contactId, exp} = TokenDataSchema.parse(
        jwtDecode(id_token)
      );

      const expiresAt = new Date(exp * 1000);

      if (expiresAt < DateTime.now().minus({minutes: 1}).toJSDate()) {
        SessionStore.write(null);
        return null;
      }

      return {
        token: id_token,
        billerId,
        contactId,
        expiresAt,
      };
    } catch {
      SessionStore.write(null);
      console.error('Invalid state stored in session localstorage');
      return null;
    }
  },
};
