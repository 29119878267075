import {navigate as navigateTo} from 'lib/navigation/routes';
import React, {useEffect, useState} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';

import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {Debbie} from 'components/organisms/Debbie';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {DatePicker} from '../shared/DatePicker';
import {useSetupRoute} from '../components/SetupRoute';
import {usePatchSearchParams} from 'lib/navigation/usePatchSearchParams';
import CurrencyInput from 'react-currency-input-field';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {AbsoluteDate, formatToCents, formatToDollars} from 'payble-shared';
import {StyledRadioGroup} from '../components/StyledRadioGroup';
import {
  getPaymentFrequencyOptions,
  PaymentFrequencyOption,
} from '../shared/PaymentFrequencyOptions';
import {APIInput} from 'payble-api-client';

const INITIAL_INSTALMENT_AMOUNT = 5000;
type GetOptionsCopy = {
  instalmentAmount: number;
  frequencyOptions: PaymentFrequencyOption[];
};
function getOptionsCopy({instalmentAmount, frequencyOptions}: GetOptionsCopy) {
  return frequencyOptions.map(option => {
    return {
      ...option,
      ...(instalmentAmount
        ? {
            label: `$${formatToDollars(instalmentAmount)} ${option.label}`,
            description: `Pay ${option.label} to catch up on your rates`,
          }
        : {}),
    };
  });
}

export const RequestPlanRequest = () => {
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = useBillerConfig();
  const navigate = useSetupNavigate();
  const {account} = useSetupRoute();

  const {instalmentFrequency, amountInCents} = useSetupSearchParams();
  const [mode, setMode] = useState(instalmentFrequency);
  const {patch} = usePatchSearchParams();

  const maxDate = AbsoluteDate.today({billerConfig}).plus({days: 44});
  const minDate = AbsoluteDate.today({billerConfig}).plus({days: 14});
  const [date, setDate] = useState(() => minDate);
  const [dateError, setDateError] = useState<Error>();
  const frequencyOptions = getPaymentFrequencyOptions();

  useEffect(() => {
    if (!billerConfig.hasRequestPlan) {
      navigateTo('/biller/:slug/setup', {slug: billerSlug});
    }
  }, [billerConfig]);

  useEffect(() => {
    if (!amountInCents) {
      patch({
        amountInCents: INITIAL_INSTALMENT_AMOUNT.toString(),
      });
    }
  }, []);

  return (
    <>
      <Debbie title="How much would like to pay?" />
      <div className="flex flex-col items-center max-w-sm m-auto">
        <div className="grid w-full gap-2 mt-6">
          <div className="space-y-4">
            <h4 className="font-semibold">Start Date</h4>
            <span className="mb-2 text-sm text-gray-600">
              The date your payments will start.
            </span>

            <DatePicker
              error={dateError}
              setError={setDateError}
              minDate={minDate}
              maxDate={maxDate}
              onChange={date => {
                patch({instalmentStartAt: date.toISO()});
                setDate(date);
              }}
              value={date}
            />
          </div>
          <div className="space-y-4">
            <h4 className="font-semibold">Instalment Amount</h4>

            <CurrencyInput
              className={
                'bg-white rounded-md shadow-sm hover:border-blue-500 border-1 w-full transition border-transparent'
              }
              placeholder="Please enter an amount"
              prefix="$"
              value={formatToDollars(amountInCents)}
              allowNegativeValue={false}
              decimalsLimit={2}
              onValueChange={(value, __name) => {
                const valueAsCents = formatToCents(
                  parseFloat(value !== undefined ? value : '0')
                );

                patch({
                  amountInCents: valueAsCents?.toString() || '',
                });
              }}
            />
          </div>
        </div>
        <StyledRadioGroup
          value={mode}
          options={getOptionsCopy({
            instalmentAmount: amountInCents,
            frequencyOptions,
          })}
          onChange={e => {
            setMode(
              e as APIInput<'consumer', 'createInstalmentPlan'>['frequency']
            );

            patch({
              instalmentStartAt: date.toISO(),
              instalmentFrequency: e,
            });
          }}
        />

        <div className="mt-6">
          <div className="mb-2">
            <h2 className="text-lg font-semibold text-gray-900">
              Amount Owing: ${formatToDollars(account.amountOwing)}
            </h2>
          </div>
          <p className="mb-2 text-sm font-medium text-red-400">
            Council is unlikely to accept a plan which takes more than 2 years
            to complete without evidence of hardship being provided.
          </p>
        </div>

        <button
          type="button"
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          disabled={!date || !amountInCents || !instalmentFrequency}
          onClick={() =>
            navigate('/biller/:slug/setup/payment-method', {
              mode: 'REQUEST_PLAN',
            })
          }
        >
          Next
        </button>
        <button
          className="mt-6 text-blue-600 transition hover:text-blue-700"
          onClick={() => navigate('/biller/:slug/request-plan')}
        >
          Back
        </button>
      </div>
    </>
  );
};
