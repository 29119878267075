import {toQueryString} from 'payble-api-client';
import {z} from 'zod';
import {useConsumerAppConfig} from '../lib/appConfig/ConsumerAppConfig';
import {useBillerConfig} from '../lib/appConfig/useBillerConfig';
import {push} from '../lib/navigation/routes';
import {FlowDefinition, StepDefinition} from './FlowDefinition';
import * as flows from './definitions';

type OnlyString<T> = T extends string ? T : never;

/**
 * @example
 * setup.account-lookup | autopay.frequency-select ...etc
 */
type FlowPath = {
  [K in keyof typeof flows]: `${K}.${OnlyString<keyof (typeof flows)[K]['steps']>}`;
}[keyof typeof flows];

type StepForPath<FP extends FlowPath> = FP extends `${infer F}.${infer S}`
  ? F extends keyof typeof flows
    ? S extends keyof (typeof flows)[F]['steps']
      ? (typeof flows)[F]['steps'][S]
      : never
    : never
  : never;

type InputForPath<FP extends FlowPath> =
  StepForPath<FP> extends StepDefinition<infer S> ? z.input<S> : never;

export const useFlowNavigate = () => {
  const billerConfig = useBillerConfig();
  const appConfig = useConsumerAppConfig();

  return <FP extends FlowPath>(path: FP, input: InputForPath<FP>) => {
    const [flowName, stepName] = path.split('.');

    const flow: FlowDefinition = (flows as any)[flowName];
    const stepDef: StepDefinition = (flows as any)?.[flowName].steps[stepName];

    const parsed = stepDef.schema({appConfig, billerConfig}).safeParse(input);

    if (!parsed.success) {
      throw parsed.error;
    }

    let qs = toQueryString(parsed.data);
    if (qs !== '') {
      qs = `?${qs}`;
    }
    const stepPath = stepDef.path ?? stepName;

    const normalizedStepPath = stepPath.length ? `/${stepPath}` : '';

    push(
      `/biller/${billerConfig.billerSlug}${flow.path}${normalizedStepPath}${qs}`
    );
  };
};
