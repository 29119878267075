import {FC, ReactNode, useState} from 'react';
import {FormField} from './FormField';
import {useRegisterField} from '../hooks/useRegisterField';
import {Autocomplete, AutocompleteProps} from './Autocomplete';
import {Controller} from 'react-hook-form';
import {useUIContext} from '../providers/PaybleUI';

export interface FormAutocompleteProps extends AutocompleteProps {
  label?: ReactNode;
  model: string;
  onSelect?: (value: string) => void;
  name: string;
  endpoint: string;
}

export const FormAutocomplete: FC<FormAutocompleteProps> = ({
  label,
  name,
  endpoint,
  onSelect,
  model,
  ...props
}) => {
  const {id, field, form} = useRegisterField(name);
  const ui = useUIContext();
  const [options, setOptions] = useState();

  return (
    <FormField id={id} name={name} label={label}>
      <Controller
        control={form.control}
        name={name}
        render={({formState, field: {onChange, ...f}}) => (
          <Autocomplete
            {...props}
            {...f}
            options={options}
            onSearchChange={async value => {
              if (ui?.api) {
                const result = await ui.api
                  .request(endpoint, {
                    data: {search: value, model},
                  })
                  .get();
                setOptions(result ?? []);
              }
            }}
            onSelect={(...args) => {
              onChange(...args);
              onSelect?.(...args);
            }}
            disabled={
              props.disabled || field.disabled || formState.isSubmitting
            }
          />
        )}
      />
    </FormField>
  );
};
