import {FC} from 'react';
import {Icon} from './Icon';

import {inputVariants} from './Input';
import {cn} from '../lib/utils';
import {AbsoluteDate} from 'payble-shared';

export type InputDateProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'value' | 'size' | 'onChange'
> & {
  value?: AbsoluteDate;
  minDate?: AbsoluteDate;
  maxDate?: AbsoluteDate;
  size?: 'default' | 'sm';
  onChange?: (value?: AbsoluteDate | null) => void;
};

const InputDate: FC<InputDateProps> = ({
  value,
  onChange = () => {},
  className,
  disabled,
  minDate,
  maxDate,
  size = 'default',
}) => {
  return (
    <div className="relative">
      <input
        type="date"
        value={value?.toISO()}
        min={minDate?.toISO()}
        max={maxDate?.toISO()}
        onChange={e => {
          onChange(AbsoluteDate.maybeFromISO(e.target.value));
        }}
        className={cn(
          inputVariants({className, size}),
          '[&::-webkit-calendar-picker-indicator]:opacity-0'
        )}
        disabled={disabled}
        onClick={e => {
          e.currentTarget.showPicker();
        }}
      />
      <div className="absolute top-0 right-0 flex items-center h-full pr-3 pointer-events-none text-icon">
        <Icon name="calendar" />
      </div>
    </div>
  );
};

InputDate.displayName = 'InputPhone';

export {InputDate};
