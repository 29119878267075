import {FC, ReactNode} from 'react';
import {FieldValues, UseFormReturn} from 'react-hook-form';
import {useForm} from '../hooks/useFormContext';

export type FormConnectFunctionContext<T extends FieldValues = FieldValues> =
  UseFormReturn<T, any, undefined> & {
    form: ReturnType<typeof useForm>;
  };

type FormConnectProps<T extends FieldValues = FieldValues> = {
  children: (values: T, context: FormConnectFunctionContext<T>) => ReactNode;
};

export const FormConnect: FC<FormConnectProps> = ({children}) => {
  const form = useForm();
  return children(form.context.watch(), {...form.context, form});
};
