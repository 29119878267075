import {Topic} from 'payble-shared';

let warningRef: ReturnType<typeof window.setTimeout> | null = null;
let expiryRef: ReturnType<typeof window.setTimeout> | null = null;

// How long before the user is warned that their session is about to expire (10 minutes)
const EXPIRY_WARNING_BUFFER_MS = 10 * 60 * 1000;

// How long before the token and auth state is wiped (10 seconds)
const EXPIRY_BUFFER_MS = 10 * 1000;

export const SessionTimeout = {
  topic: Topic.create<
    {type: 'session_expiring_soon'} | {type: 'session_expiring_now'}
  >({onSubscriberErr: console.error}),

  write(value: {expiresAt: Date} | null) {
    if (warningRef) {
      clearTimeout(warningRef);
      warningRef = null;
    }
    if (expiryRef) {
      clearTimeout(expiryRef);
      expiryRef = null;
    }

    if (value) {
      const {expiresAt} = value;
      const now = new Date();
      const timeToExpiry = expiresAt.getTime() - now.getTime();

      if (timeToExpiry > EXPIRY_WARNING_BUFFER_MS) {
        warningRef = setTimeout(() => {
          SessionTimeout.topic.publish({type: 'session_expiring_soon'});
        }, timeToExpiry - EXPIRY_WARNING_BUFFER_MS);
      }

      if (timeToExpiry > EXPIRY_BUFFER_MS) {
        expiryRef = setTimeout(() => {
          SessionTimeout.topic.publish({type: 'session_expiring_now'});
        }, timeToExpiry - EXPIRY_BUFFER_MS);
      }
    }
  },
};

// @ts-expect-error This is used by e2e tests to manually trigger session expiry
window.timeoutTopic = SessionTimeout.topic;
