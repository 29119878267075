import {
  GetAccountByExternalIdResponse,
  useGetAccountFromSearchParams,
} from 'lib/account/useGetAccountFromSearchParams';
import {Button, Dialog, DialogContent, DialogFooter} from 'payble-ui';
import {ReactNode, useEffect, useState} from 'react';
import {useSetupNavigate} from '../hooks/useSetupNavigate';

function WarningDialog({
  open,
  onClose,
  content,
  children,
}: {
  open: boolean;
  onClose: () => void;
  content: ReactNode;
  children: ReactNode;
}) {
  return (
    <Dialog open={open} onOpenChange={isOpen => !isOpen && onClose()}>
      <DialogContent className="max-w-xl">
        {content}
        <DialogFooter className="gap-2">{children}</DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

const toWarningDialogState = (
  warnings: GetAccountByExternalIdResponse['warnings']
) => {
  return (
    warnings?.map(warning => {
      return {
        ...warning,
        opened: true,
      };
    }) ?? []
  );
};

export function AccountWarningModal({onConfirm}: {onConfirm: () => void}) {
  const navigate = useSetupNavigate();
  const account = useGetAccountFromSearchParams();

  const data = account.data;

  const [warnings, setWarnings] = useState(
    toWarningDialogState(data?.warnings)
  );

  useEffect(() => {
    setWarnings(toWarningDialogState(data?.warnings));
  }, [data?.warnings]);

  const closeWarning = (type: string) => () => {
    setWarnings(
      warnings.map(warning =>
        warning.type === type ? {...warning, opened: false} : warning
      )
    );
  };

  return warnings.map(warning => (
    <WarningDialog
      key={warning.type}
      open={warning.opened}
      onClose={closeWarning(warning.type)}
      content={warning.message}
    >
      {warning.ownType === 'ownedByOther' && (
        <Button variant="outline" onClick={closeWarning(warning.type)}>
          Cancel
        </Button>
      )}
      {warning.ownType === 'owned' && (
        <Button
          variant="outline"
          onClick={() => {
            navigate('/biller/:slug');
          }}
        >
          Home page for more information
        </Button>
      )}
      <Button
        onClick={() => {
          if (
            warnings.some(({type, opened}) => type !== warning.type && opened)
          ) {
            closeWarning(warning.type)();
          } else {
            onConfirm();
          }
        }}
      >
        Proceed with payment
      </Button>
    </WarningDialog>
  ));
}
