import {
  InvalidateQueryFilters,
  QueryClient,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  APIClient,
  APIQueryConfig,
  AnyAPISpec,
  HTTPEndpointRequestData,
  HTTPEndpointResponse,
  HTTPQueryKey,
} from 'payble-api-client';
import {errs} from 'payble-shared';
import {DomainError} from 'payble-shared/src/errs';

type MakeAPIHooks = <S extends AnyAPISpec>(
  spec: S,
  queryConfig: APIQueryConfig<S>,
  useAPI: () => {api: APIClient<S>}
) => {
  useAPIInvalidate: () => (filters?: InvalidateQueryFilters) => Promise<void>;
  useAPIMutation: APIMutatorHook<S>;
  useAPIQuery: APIQueryHook<S>;
};

type APIMutatorHook<S extends AnyAPISpec> = <OP extends keyof S>(
  operation: OP,
  options?: {
    query?: Omit<
      UseMutationOptions<
        HTTPEndpointResponse<S[OP]>,
        errs.DomainError,
        HTTPEndpointRequestData<S[OP]>,
        unknown
      >,
      'mutationFn'
    >;
  }
) => UseMutationResult<
  HTTPEndpointResponse<S[OP]>,
  errs.DomainError,
  HTTPEndpointRequestData<S[OP]>,
  unknown
>;

type APIQueryHook<S extends AnyAPISpec> = <OP extends keyof S>(
  operation: OP,
  options: {
    data?: HTTPEndpointRequestData<S[OP]>;
    query?: Omit<
      UseQueryOptions<
        HTTPEndpointRequestData<S[OP]>,
        errs.DomainError,
        HTTPEndpointResponse<S[OP]>,
        HTTPQueryKey<S[OP]>
      >,
      'queryKey' | 'queryFn'
    > & {keepPreviousData?: boolean};
  }
) => UseQueryResult<HTTPEndpointResponse<S[OP]>, errs.DomainError>;

/**
 * Used to hook directly into the API client and react-query via the success topic.
 */
export const makeInvalidator = <S extends AnyAPISpec>(
  queryClient: QueryClient,
  queryConfig: APIQueryConfig<S>
) => {
  return async (success: {
    endpoint: string;
    response: unknown;
    request: unknown;
  }) => {
    const invalidations = queryConfig[success.endpoint]?.invalidates?.(
      success.request as any,
      success.response as any
    );
    if (invalidations) {
      await Promise.all(
        invalidations.map(async queryKey => {
          await queryClient.invalidateQueries({
            queryKey,
          });
        })
      );
    }
  };
};

export const makeErrorInvalidator = <S extends AnyAPISpec>(
  queryClient: QueryClient,
  queryConfig: APIQueryConfig<S>
) => {
  return async (failure: {
    endpoint: string;
    request: unknown;
    error: DomainError;
  }) => {
    const invalidations = queryConfig[failure.endpoint]?.errorInvalidates?.(
      failure.request as any,
      failure.error
    );
    if (invalidations) {
      await Promise.all(
        invalidations.map(async queryKey => {
          await queryClient.invalidateQueries({
            queryKey,
          });
        })
      );
    }
  };
};

export const makeAPIHooks: MakeAPIHooks = (spec, queryConfig, useAPI) => {
  return {
    useAPIInvalidate: () => {
      const queryClient = useQueryClient();

      return (filters?: InvalidateQueryFilters) => {
        return queryClient.invalidateQueries(filters);
      };
    },

    useAPIQuery: (operation, options) => {
      const {api} = useAPI();
      const queryClient = useQueryClient();

      const definition = spec[operation];
      if (!definition) {
        throw new Error(
          `Invalid endpoint definition for ${operation as string}. Not in specification.`
        );
      }
      const config = queryConfig[operation];

      return useQuery({
        refetchOnWindowFocus: false,
        staleTime: config?.staleTime,
        ...options?.query,
        enabled:
          options?.query?.enabled ?? (options.data == null ? false : true),
        queryKey: definition.queryKey(options.data),
        queryFn: (async ({signal}: {signal: AbortSignal}) => {
          const {data} = options;

          return await api
            .request(operation, {data, abort: signal})
            .tap(response => {
              config?.onSuccess?.({
                request: data as any,
                response,
                updateCache: (queryKey, updater) =>
                  queryClient.setQueryData(queryKey, updater as any),
              });
            })
            .get();
        }) as any,
        placeholderData: options?.query?.keepPreviousData
          ? keepPreviousData
          : undefined,
      }) as any;
    },

    useAPIMutation: (operation, options) => {
      const {api} = useAPI();
      const queryClient = useQueryClient();

      const config = queryConfig[operation];

      return useMutation({
        mutationKey: [operation],
        ...options?.query,
        mutationFn: async data => {
          return await api
            .request(operation, {data})
            .tap(response => {
              config?.onSuccess?.({
                request: data,
                response,
                updateCache: (queryKey, updater) =>
                  queryClient.setQueryData(queryKey, updater as any),
              });
            })
            .get();
        },
      });
    },
  };
};
