import {z} from 'zod';
import {defineFlow, defineStep} from '../FlowDefinition';

import {useFlowNavigate} from '../flows';
import {PaymentAmountStep} from './steps/pay-now/PaymentAmountStep';
import {PayStep} from './steps/pay-now/payStep';
import {AccountLookupStep} from './steps/shared/AccountLookupStep';
import {AccountProvider} from './providers/AccountProvider';
import {BaseSetupPage} from '../../components/layout';

export const PayNow = defineFlow({
  path: '/pay-now-v2',
  available: ({billerConfig, appConfig}) =>
    !!billerConfig.features.CONTACTLESS_PAYMENTS &&
    appConfig.stage !== 'production',
  steps: {
    lookup: defineStep(
      {
        path: '',
        schema: () => z.object({}),
      },
      () => {
        const next = useFlowNavigate();
        return (
          <BaseSetupPage>
            <AccountLookupStep
              onNext={({accountType, accountExternalId, verificationCode}) => {
                next('PayNow.payment-amount', {
                  accountType,
                  accountExternalId,
                  verificationCode,
                });
              }}
            />
          </BaseSetupPage>
        );
      }
    ),

    'payment-amount': defineStep(
      {
        schema: () =>
          z.object({
            accountType: z.string(),
            accountExternalId: z.string(),
            verificationCode: z.string().optional(),
          }),
      },

      ({
        state: {accountExternalId, accountType, verificationCode},
        config: {billerConfig},
      }) => {
        const next = useFlowNavigate();
        return (
          <BaseSetupPage>
            <AccountProvider
              accountType={accountType}
              externalId={accountExternalId}
              verificationCode={verificationCode}
              billerSlug={billerConfig.billerSlug}
              anonymous={true}
            >
              <PaymentAmountStep
                accountExternalId={accountExternalId}
                accountType={accountType}
                verificationCode={verificationCode}
                onNext={({amountInCents}) => {
                  next('PayNow.pay', {
                    accountType,
                    accountExternalId,
                    amountInCents,
                    verificationCode,
                  });
                }}
              />
            </AccountProvider>
          </BaseSetupPage>
        );
      }
    ),

    pay: defineStep(
      {
        schema: () =>
          z.object({
            accountType: z.string(),
            accountExternalId: z.string(),
            amountInCents: z.string(),
            verificationCode: z.string().optional(),
          }),
      },
      ({
        state: {
          accountExternalId,
          accountType,
          verificationCode,
          amountInCents,
        },
        config: {billerConfig},
      }) => {
        return (
          <BaseSetupPage>
            <AccountProvider
              accountType={accountType}
              externalId={accountExternalId}
              verificationCode={verificationCode}
              billerSlug={billerConfig.billerSlug}
              anonymous={true}
            >
              <PayStep
                accountExternalId={accountExternalId}
                accountType={accountType}
                verificationCode={verificationCode}
                amountInCents={amountInCents}
              />
            </AccountProvider>
          </BaseSetupPage>
        );
      }
    ),
  },
});
