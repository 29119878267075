import {navigate} from 'lib/navigation/routes';
import {getBillerSlugFromUrl} from 'lib/url';
import {GetAccountByExternalIdResponse} from '../../../lib/account/useGetAccountFromSearchParams';
import React, {ReactNode, createContext, useContext} from 'react';
import {Loading} from 'components/atoms/Loading';
import {getAccount} from 'lib/account/getAccount';

type AccountProviderValue = {
  account: GetAccountByExternalIdResponse;
};
const AccountContext = createContext<AccountProviderValue>(null!);

export const useAccountProvider = () => {
  const account = useContext(AccountContext);
  if (account == null) {
    throw new Error('No account provided');
  }
  return account;
};

export const AccountProvider = ({
  children,
  accountType,
  externalId,
  verificationCode,
  billerSlug,
  anonymous,
}: {
  children: ReactNode;
  accountType: string;
  externalId: string;
  verificationCode?: string;
  billerSlug: string;
  anonymous: boolean;
}) => {
  const {
    loading,
    data: account,
    error,
  } = getAccount({
    accountType,
    externalId,
    verificationCode,
    billerSlug,
    anonymous,
  });

  if (loading) return <Loading />;

  if (!account || error) {
    navigate('/biller/:slug', {
      slug: getBillerSlugFromUrl(),
    });

    return null;
  }

  return (
    <AccountContext.Provider value={{account}}>
      {children}
    </AccountContext.Provider>
  );
};
