import * as React from 'react';
import {Slot} from '@radix-ui/react-slot';
import {VariantProps, cn, cva} from '../lib/utils';
import {Loading} from './Loading';
import {IconResolver, IconResolverIcon} from './IconResolver';

const buttonVariants = cva(
  'inline-flex gap-1 items-center justify-center whitespace-nowrap rounded-md text-md font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-40 disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        secondary: 'bg-border text-border-foreground hover:bg-border/90',
        outline:
          'border border-primary bg-background hover:bg-primary/10 text-primary',
        ghost: 'hover:bg-secondary hover:text-secondary-foreground',
        link: 'text-primary underline-offset-4 hover:underline disabled:text-primary/20 disabled:bg-transparent',
        accent: 'bg-accent text-accent-foreground hover:bg-accent/90',
        destructiveOutline:
          'border border-destructive bg-background hover:bg-destructive/10 text-destructive',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        warning: 'bg-amber-300 text-white hover:bg-amber-300/90',
      },
      full: {
        true: 'w-full',
        false: '',
      },
      size: {
        default: 'h-12 px-4 py-2',
        sm: 'h-9 px-3 text-sm',
        lg: 'h-14 px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      full: true,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLElement>,
    VariantProps<typeof buttonVariants> {
  as?: 'button' | 'span' | 'div' | 'a';
  full?: boolean;
  isLoading?: boolean;
  asChild?: boolean;
  iconL?: IconResolverIcon;
  iconR?: IconResolverIcon;
  href?: string;
  target?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      as = 'button',
      full = true,
      asChild = false,
      children,
      isLoading = false,
      iconL,
      iconR,
      type = 'button',
      ...props
    }: ButtonProps,
    ref
  ) => {
    const Comp = asChild ? Slot : as;
    return (
      <Comp
        className={cn(buttonVariants({variant, size, full, className}))}
        {...props}
        type={type}
        disabled={isLoading || props.disabled}
        ref={ref as any}
      >
        {isLoading && <Loading variant="button" centered={false} />}
        {!isLoading && (
          <>
            <IconResolver icon={iconL} />
            {children}
            <IconResolver icon={iconR} />
          </>
        )}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export {Button, buttonVariants};
