import {useAPIQuery} from 'lib/api';
import {APIOutput} from 'payble-api-client';
import {errs} from 'payble-shared';
import {DomainError} from 'payble-shared/src/errs';

function getError(
  err: DomainError | null,
  data?:
    | APIOutput<'consumer', 'getAccountByExternalId'>
    | APIOutput<'consumer', 'getAccountByExternalIdAnonymously'>
    | null
) {
  let requiresVerification = false;
  let error;

  if (err) {
    error = err.message;
  }

  if (err instanceof errs.AccountVerificationError) {
    requiresVerification = true;
  } else if (data === null) {
    error = "Sorry, we couldn't find that account. Please try again.";
  }

  return {error, requiresVerification};
}

type GetAccount = {
  accountType: string;
  externalId: string;
  billerSlug: string;
  anonymous: boolean;
  verificationCode?: string;
};

export function getAccount(
  {
    accountType,
    externalId,
    verificationCode,
    billerSlug,
    anonymous,
  }: GetAccount,
  __useAPIQuery: typeof useAPIQuery = useAPIQuery
) {
  // Hack for Yarra Ranges Council to just get them live 22-01-2025
  const accountExternalId =
    billerSlug === 'yarra-ranges-council' && externalId && accountType
      ? // worried about old browsers not supporting replaceAll
        externalId.replace(/\//g, '')
      : externalId;

  const {
    data,
    error: getAccountError,
    isLoading: loading,
    refetch,
  } = __useAPIQuery(
    anonymous ? 'getAccountByExternalIdAnonymously' : 'getAccountByExternalId',
    {
      data:
        accountExternalId && accountType
          ? {billerSlug, accountExternalId, accountType, verificationCode}
          : undefined,
      query: {
        // cache it for 300s (same as old graphql default)
        // we do not want to refetch account unless user made a change
        staleTime: 300_000,
        keepPreviousData: true,
        retry: (tries, error) =>
          !(error instanceof errs.AccountVerificationError) && tries < 3,
      },
    }
  );

  const {error, requiresVerification} = getError(getAccountError, data);

  return {
    loading,
    data,
    error,
    requiresVerification,
    refetch,
  };
}
