import {apiSchema} from 'payble-api-client';
import {FC} from 'react';

interface CheckoutActionPreviewProps {
  preview: apiSchema.CheckoutPreview;
}

export const CheckoutActionPreview: FC<CheckoutActionPreviewProps> = ({
  preview,
}) => {
  if (preview.type === 'plan') {
    return (
      <div className="flex flex-col justify-center flex-1 h-full gap-1">
        {preview.schedule.map((item, idx) => {
          if (item.type === 'headline') {
            return (
              <h4
                key={idx}
                className="mb-2 ml-2 text-sm font-bold text-left sm:text-lg"
              >
                {item.content}
              </h4>
            );
          }
          if (item.type === 'simple_payment_schedule') {
            return (
              <div key={idx}>
                {item.header && (
                  <h4
                    key={idx + 'header'}
                    className="mb-2 ml-2 text-sm font-bold text-left sm:text-lg"
                  >
                    {item.header}
                  </h4>
                )}
                {item.rows.map((row, idx) => {
                  if (row.style === 'header') {
                    return (
                      <div
                        key={idx}
                        className="flex py-2 text-xs font-semibold sm:text-sm"
                      >
                        <div className="px-2 basis-6/12">{row.l}</div>
                        <div className="px-2 text-right basis-6/12">
                          {row.r}
                        </div>
                      </div>
                    );
                  }

                  if (row.style === 'row' || row.style === 'danger-row') {
                    return (
                      <div
                        key={idx}
                        className={`py-2 flex text-xs sm:text-sm ${
                          row.style === 'danger-row' ? 'text-red-500' : ''
                        }`}
                      >
                        <div className="px-2 basis-6/12">
                          <div>{row.l}</div>
                        </div>
                        <div className="px-2 text-right basis-6/12">
                          {row.r}
                        </div>
                      </div>
                    );
                  }

                  if (row.style === 'footer') {
                    return (
                      <div className="flex py-2 text-xs sm:text-sm">
                        <div className="px-2 font-bold basis-6/12">{row.l}</div>
                        <div className="px-2 font-bold text-right basis-6/12">
                          {row.r}
                        </div>
                      </div>
                    );
                  }

                  const __: never = row.style;
                  throw new Error(`Unknown row style ${__}`);
                })}
              </div>
            );
          }

          if (item.type === 'light_note') {
            return <p className="p-4 font-light">{item.content}</p>;
          }
          if (item.type === 'important_note') {
            return (
              <p className="p-4 font-light">
                <b>Important</b>: {item.content}
              </p>
            );
          }

          const __: never = item;

          throw new Error(`Unknown item type ${__}`);
        })}
      </div>
    );
  }

  if (preview.type === 'payment') {
    return (
      <p className="p-4 font-light">
        <b>Paying {preview.date ? preview.date : 'now'}</b>:{' '}
        {preview.formattedTotal}
      </p>
    );
  }

  if (preview.type === 'payment_with_surcharge') {
    return (
      <>
        <p className="p-4 font-light">
          <b>Paying {preview.date ? preview.date : 'now'}</b>:{' '}
          {preview.formattedTotal}
        </p>

        <p className="p-4 font-light">
          {preview.formattedPrincipal} + <b>{preview.prettyRate} Surcharge</b>:{' '}
          {preview.formattedSurcharge}
        </p>
      </>
    );
  }

  return <>Unno</>;
};
