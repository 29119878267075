import {Debbie} from 'components/organisms/Debbie';
import {HomeIcon} from '@heroicons/react/20/solid';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {navigate} from 'lib/navigation/routes';
import {NotificationSettingTable} from 'payble-app-shared/src/components/NotificationSettingTable';
import {useAPIMutation, useAPIQuery} from 'lib/api';
import {MaxContainer} from 'payble-ui';
import {auth} from '../../lib/authv2/auth';

export const NotificationSetting = () => {
  const contact = auth.useContact();

  const billerConfig = useBillerConfig();
  const {mutateAsync: update} = useAPIMutation('updateNotificationSettings');
  const {
    data: defaultValues,
    dataUpdatedAt,
    isPending,
  } = useAPIQuery('getNotificationSettings', {query: {enabled: true}});

  return (
    <MaxContainer>
      <Debbie
        title={'Manage your notification preferences'}
        message="Some of the messages you get are optional. You can adjust your preferences here."
      />

      <div className="flex mt-5 mb-2">
        <div className="flex-1">
          <nav className="flex" aria-label="Breadcrumb">
            <ol
              role="list"
              className="flex px-6 space-x-4 bg-white rounded-md shadow"
            >
              <li className="flex">
                <div className="flex items-center">
                  <button
                    onClick={() =>
                      navigate('/biller/:slug', {
                        slug: billerConfig.billerSlug,
                      })
                    }
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="flex-shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </button>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <button
                    onClick={() =>
                      navigate('/biller/:slug/notification', {
                        slug: billerConfig.billerSlug,
                      })
                    }
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    Notification settings
                  </button>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="flex pl-4"></div>
      </div>

      <div className="mt-5 overflow-hidden shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Notification settings
            </h3>
            <p className="mt-1 mb-4 text-sm text-gray-500">
              Manage your non-critical notifications
            </p>
            <NotificationSettingTable
              disabledType={{
                email: !contact.email,
                sms: !contact.mobile,
              }}
              defaultValues={defaultValues}
              key={dataUpdatedAt}
              disabled={isPending}
              onSave={update}
            />
          </div>
        </div>
      </div>
    </MaxContainer>
  );
};
