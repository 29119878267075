import {z} from 'zod';
import {StartCheckoutRequestSchema} from 'payble-api-client/schemas/checkout';
import {useEffect} from 'react';
import {useAPIMutation} from '../../lib/api';
import {
  CheckoutActionPreview,
  ReceiptView,
  TermBody,
  useToast,
} from 'payble-ui';
import {flow} from 'payble-ui';
import {defineFlow, defineStep} from '../../flows/FlowDefinition';
import {useFlowNavigate} from '../../flows/flows';
import {InjectCheckout} from './InjectCheckout';
import {useCheckoutNavigate} from './useCheckoutNavigate';
import {useNavigate} from 'react-router';
import {CheckoutTokens} from './CheckoutTokens';
import {BaseSetupPage} from '../../components/layout';
import {ErrorPage} from '../navigation/Error';

export const checkout = defineFlow({
  path: '/checkout',
  available: ({appConfig}) => appConfig.infraEnvironment === 'develop',
  steps: {
    // This is a temporary route just for debugging since this won't be hooked
    // to any flows to begin with
    init: defineStep(
      {
        schema: () => z.object({}),
        requiresAuth: () => false,
      },
      () => {
        const next = useFlowNavigate();

        useEffect(() => {
          next('checkout.start', {
            account: {
              externalId: '100347',
              type: 'rates',
            },
            billerId: '898ec588-41ea-4834-93c4-2fea2442eb89',
            checkout: {
              type: 'pay',
              amount: 666_00,
            },
          });
        }, []);

        return (
          <BaseSetupPage>
            <>Inittting</>
          </BaseSetupPage>
        );
      }
    ),

    start: defineStep(
      {
        schema: () => StartCheckoutRequestSchema,
        requiresAuth: () => false,
      },
      ({state}) => {
        const next = useFlowNavigate();
        const navigate = useNavigate();

        const start = useAPIMutation('startCheckout', {
          query: {
            onError: console.log,
            onSuccess: ({ct, checkout}) => {
              // TODO: Add source path here somehow?
              CheckoutTokens.write(checkout.checkoutId, {ct});
              const {forward} = useCheckoutNavigate({
                navigate,
                next,
                checkout,
                checkoutId: checkout.checkoutId,
                currentStep: 'NONE',
              });
              forward();
            },
          },
        });

        useEffect(() => {
          start.mutate(state);
        }, []);

        if (start.error) {
          return (
            <ErrorPage
              title="Could not start checkout"
              subtitle={start.error.message}
            />
          );
        }

        return (
          <BaseSetupPage>
            <>Make believe I'm loading</>
          </BaseSetupPage>
        );
      }
    ),
    preview: defineStep(
      {
        schema: () => z.object({checkoutId: z.string()}),
        requiresAuth: () => false,
      },
      ({state: {checkoutId}}) => {
        return (
          <BaseSetupPage>
            <InjectCheckout checkoutId={checkoutId} currentStep="preview">
              {({checkout: {preview}, forward, back}) => (
                <>
                  <flow.Debbie title="How does this look to you?" />
                  <flow.BasicCard>
                    <CheckoutActionPreview preview={preview} />
                  </flow.BasicCard>
                  <flow.ActionButton onClick={forward}>Next</flow.ActionButton>
                  {back && (
                    <flow.BackButton onClick={back}>Back</flow.BackButton>
                  )}
                </>
              )}
            </InjectCheckout>
          </BaseSetupPage>
        );
      }
    ),
    'create-payment-method': defineStep(
      {
        schema: () => z.object({checkoutId: z.string()}),
        requiresAuth: () => false,
      },
      ({state: {checkoutId}}) => {
        const addPaymentMethod = useAPIMutation('addCheckoutPaymentMethod');
        const {toast} = useToast();
        return (
          <BaseSetupPage>
            <InjectCheckout
              checkoutId={checkoutId}
              currentStep="create_payment_method"
            >
              {({back, forward, ct}) => (
                <>
                  <flow.Debbie title="How do you want to pay?" />
                  <flow.BasicCard>
                    <p>Payment method form</p>
                  </flow.BasicCard>
                  <flow.ActionButton
                    onClick={() => {
                      addPaymentMethod.mutate(
                        {
                          ct,
                          type: 'create',
                          paymentMethod: {
                            type: 'direct_debit',
                            bsb: '062-111',
                            accountNumber: '44444444',
                            accountName: 'Babbys savings',
                          },
                        },
                        {
                          onSuccess: forward,
                          onError: e => {
                            toast({
                              title: e.message,
                            });
                          },
                        }
                      );
                    }}
                  >
                    Next
                  </flow.ActionButton>
                  {back && (
                    <flow.BackButton onClick={back}>Back</flow.BackButton>
                  )}
                </>
              )}
            </InjectCheckout>
          </BaseSetupPage>
        );
      }
    ),

    'select-payment-method': defineStep(
      {
        schema: () => z.object({checkoutId: z.string()}),
        requiresAuth: () => false,
      },
      ({state: {checkoutId}}) => {
        const addPaymentMethod = useAPIMutation('addCheckoutPaymentMethod');
        const {toast} = useToast();
        return (
          <BaseSetupPage>
            <InjectCheckout
              checkoutId={checkoutId}
              currentStep="select_payment_method"
            >
              {({back, ct, forward}) => (
                <>
                  <flow.Debbie title="How do you want to pay?" />
                  <flow.BasicCard>
                    <p>Payment method form</p>
                  </flow.BasicCard>
                  <flow.ActionButton
                    onClick={() => {
                      addPaymentMethod.mutate(
                        {
                          ct,
                          type: 'create',
                          paymentMethod: {
                            type: 'direct_debit',
                            bsb: '062-111',
                            accountNumber: '44444444',
                            accountName: 'Babbys savings',
                          },
                        },
                        {
                          onSuccess: forward,
                          onError: e => {
                            toast({
                              title: e.message,
                            });
                          },
                        }
                      );
                    }}
                  >
                    Next
                  </flow.ActionButton>
                  {back && (
                    <flow.BackButton onClick={back}>Back</flow.BackButton>
                  )}
                </>
              )}
            </InjectCheckout>
          </BaseSetupPage>
        );
      }
    ),

    terms: defineStep(
      {
        schema: () => z.object({checkoutId: z.string()}),
        requiresAuth: () => false,
      },
      ({state: {checkoutId}}) => {
        return (
          <BaseSetupPage>
            <InjectCheckout checkoutId={checkoutId} currentStep="terms">
              {({step: {terms}, back, forward}) => (
                <>
                  <flow.Debbie title="What do you think of these bad boys?" />
                  <flow.BasicCard>
                    <TermBody term={terms} />
                  </flow.BasicCard>
                  <flow.ActionButton onClick={forward}>
                    I agree to all these very interesting terms
                  </flow.ActionButton>
                  {back && (
                    <flow.BackButton onClick={back}>Back</flow.BackButton>
                  )}
                </>
              )}
            </InjectCheckout>
          </BaseSetupPage>
        );
      }
    ),

    confirm: defineStep(
      {
        schema: () => z.object({checkoutId: z.string()}),
        requiresAuth: () => true,
      },
      ({state: {checkoutId}}) => {
        return (
          <BaseSetupPage>
            <InjectCheckout checkoutId={checkoutId} currentStep="confirm">
              {({back, ct}) => {
                const next = useFlowNavigate();
                const donesies = useAPIMutation('completeCheckout');
                const {toast} = useToast();
                return (
                  <>
                    <flow.Debbie title="Now hit the pay button" />
                    <flow.BasicCard>
                      <p>Wanna pay now?</p>
                    </flow.BasicCard>
                    <flow.ActionButton
                      onClick={() => {
                        donesies.mutate(
                          {ct},
                          {
                            onSuccess: () => {
                              next('checkout.receipt', {checkoutId});
                            },

                            onError: e => {
                              toast({
                                title: e.message,
                              });
                            },
                          }
                        );
                      }}
                    >
                      Okay now you pay
                    </flow.ActionButton>
                    {back && (
                      <flow.BackButton onClick={back}>Back</flow.BackButton>
                    )}
                  </>
                );
              }}
            </InjectCheckout>
          </BaseSetupPage>
        );
      }
    ),

    receipt: defineStep(
      {
        schema: () => z.object({checkoutId: z.string()}),
        requiresAuth: () => true,
      },
      ({state: {checkoutId}}) => {
        return (
          <BaseSetupPage>
            <InjectCheckout checkoutId={checkoutId} currentStep="confirm">
              {({checkout}) => {
                if (!checkout.receipt) {
                  throw new Error('No receipt');
                }

                return (
                  <>
                    <flow.Debbie title="Good job getting through all that" />
                    <flow.BasicCard>
                      <ReceiptView lines={checkout.receipt.items} />
                    </flow.BasicCard>
                    <flow.ActionButton
                      onClick={() => {
                        console.log('Where do we go now');
                      }}
                    >
                      all done
                    </flow.ActionButton>
                  </>
                );
              }}
            </InjectCheckout>
          </BaseSetupPage>
        );
      }
    ),
  },
});
