import {z} from 'zod';
import {zPhoneNumber} from '../schemas';
import {
  ConsumerContactResponseSchema,
  contactSourceSchema,
} from '../schemas/contact';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';

export const getSessionContact = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getContact',
  queryKey: () => ['user', 'contact'],
  requestSchema: null,
  responseSchema: ConsumerContactResponseSchema,
});

export const updateContact = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'PUT /updateContact',
  requestSchema: z.object({
    givenName: z.string().optional(),
    familyName: z.string().optional(),
    title: z.string().optional(),
    email: z.string().email().optional(),
    mobile: zPhoneNumber.optional(),
    source: contactSourceSchema.optional(),
    sendReceipt: z.boolean().optional(),
  }),
  responseSchema: ConsumerContactResponseSchema,
});
