import {
  CheckoutStep,
  CheckoutStepByType,
} from 'payble-api-client/schemas/checkout';
import {useAPIQuery} from '../../lib/api';
import {APIOutput} from 'payble-api-client/utils/typeHelpers';
import React, {useMemo} from 'react';
import {useCheckoutNavigate} from './useCheckoutNavigate';
import {Spinner} from '../../components/atoms/Spinner';
import {CheckoutTokens} from './CheckoutTokens';
import {flow} from 'payble-ui';

export const InjectCheckout = <T extends CheckoutStep['step']>(props: {
  checkoutId: string;
  currentStep: T;
  children: (args: {
    forward: () => void;
    back?: () => void;
    ct: string;
    step: CheckoutStepByType<T>;
    checkout: APIOutput<'consumer', 'getCheckout'>;
  }) => React.ReactNode;
}) => {
  const {children, checkoutId} = props;

  const {ct} = useMemo(() => CheckoutTokens.read(checkoutId), [checkoutId]);

  const checkout = useAPIQuery('getCheckout', {
    data: {ct},
  });

  const {forward, back} = useCheckoutNavigate({
    checkout: checkout.data ?? null,
    checkoutId,
    currentStep: props.currentStep,
  });

  if (checkout.error) {
    return <pre>{JSON.stringify(checkout.error, null, 2)}</pre>;
  }

  if (!checkout.data) {
    return (
      <flow.BasicCard>
        <div className="flex justify-center pb-16">
          <Spinner />
        </div>
      </flow.BasicCard>
    );
  }

  const step = checkout.data.steps.find(s => s.step === props.currentStep);
  if (!step) {
    throw new Error(
      `Invalid step state, ${checkout.data.steps.map(s => s.step).join(', ')}: got: ${props.currentStep}`
    );
  }

  return (
    <>
      {children({
        forward,
        ct,
        back,
        step: step as CheckoutStepByType<T>,
        checkout: checkout.data,
      })}
    </>
  );
};
